<template>
    <span v-if="contact">
        <router-link :to="'/'+$constants.entities.Contact.ROUTE+contact.id" @click.stop v-html="formatSearchName(contact.name, searchTerm)"></router-link>
        <entity-tag-chip v-if="false" :object="contact" class="ml-1"></entity-tag-chip>
    </span>
</template>
<style scoped>
</style>
<script>
    import { formatSearchName } from "../mixins/searchMixins";
    import { mapActions } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "ContactLink",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            contact: Object,
            searchTerm: String
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            formatSearchName
        },
        computed: {
        }
    };
</script>