import api from '../../helpers/api';
import entities from '../../constants/entities';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import jobs from '../../constants/jobs';
const signalR = require("@microsoft/signalr");
export const smart = {
    state: {
        loadingData: true,
        refreshRequired: false,
        smartData: null,
        modalJob: null,
        smartStartDate: null,
        smartEndDate: null,
        showJobDialog: false,
        showJobCompleteDialog: false,
        showJobReworkDialog: false,
        showRefreshRequired: false,
        showJobPretreatmentDialog: false,
        pretreatmentJob: null,
        pretreatSaving: false,
        pretreatmentTableData: [],
        pretreatmentTableDataLoading: false,
        showBulkSchedule: false,
        partiallyCompleted: false,
        smartKey: 0,
        smartLock: false,
        bulkUpdateJobs: [],
        smartScrollPos: 0,
        smartScrollLeft: 0,
        hideArchived: false
    },
    mutations: {
        setSmartHideArchived(state, hide) {
            state.hideArchived = hide;
        },
        setSmartScrollPos(state, smartScrollPos) {
            state.smartScrollPos = smartScrollPos;
        },
        setSmartScrollLeft(state, smartScrollLeft) {
            state.smartScrollLeft = smartScrollLeft;
        },
        setBulkUpdateJobs(state, bulkJobs) {
            state.bulkUpdateJobs = bulkJobs;
        },
        setSmartLock(state, smartLock) {
            state.smartLock = smartLock;
        },
        setPartiallyComplete(state, partiallyCompleted) {
            state.partiallyCompleted = partiallyCompleted;
        },
        setShowBulkSchedule(state, showBulkSchedule) {
            state.showBulkSchedule = showBulkSchedule;
        },
        setPretreatmentTableDataLoading(state, loading) {
            state.pretreatmentTableDataLoading = loading;
        },
        setPretreatmentTableData(state, data) {
            state.pretreatmentTableData = data;
        },
        setPretreatmentJob(state, pretreatmentJob) {
            state.pretreatmentJob = pretreatmentJob;
        },
        setShowJobPretreatmentDialog(state, showJobPretreatmentDialog) {
            state.showJobPretreatmentDialog = showJobPretreatmentDialog;
        },
        setRefreshRequired(state, refreshRequired) {
            state.refreshRequired = refreshRequired;
        },
        setLoadingData(state, loading) {
            state.loadingData = loading;
        },
        setSmartData(state, smartData) {
            state.smartData = smartData;
            state.smartKey++;
        },
        setDialogJob(state, dialogJob) {
            state.dialogJob = dialogJob;
        },
        setShowJobDialog(state, showJobDialog) {
            state.showJobDialog = showJobDialog;
        },
        setShowJobCompleteDialog(state, showJobCompleteDialog) {
            state.showJobCompleteDialog = showJobCompleteDialog;
        },
        setShowJobReworkDialog(state, showJobReworkDialog) {
            state.showJobReworkDialog = showJobReworkDialog;
        },
        setSmartStartDate(state, startDate) {
            state.smartStartDate = startDate;
        },
        setSmartEndDate(state, endDate) {
            state.smartEndDate = endDate;
        },
        setPretreatSaving(state, saving) {
            state.pretreatSaving = saving;
        },
        updateDialogJob(state, job) {
            if (state.smartData && state.dialogJob) {
                state.dialogJob = job;
                // Update job in columns
                state.smartData.forEach(column => {
                    let index = column.jobs.findIndex(item => item.jobTaskId == job.jobTaskId);
                    if (index > -1) {
                        column.jobs[index] = job;
                    }
                });
            }
            state.smartKey++;
        },
        updateSmartColumn(state, column) {
            let index = state.smartData.findIndex(item => item.name == column.name);
            if (index > -1) {
                state.smartData[index] = column;
            }
            state.smartKey++;
        }
    },
    actions: {
        initSmart({ commit, dispatch, state, rootState }) {
            var cookieStart = Cookies.get('smart-start-date');
            var cookieEnd = Cookies.get('smart-end-date');
            if (cookieStart && cookieEnd) {
                commit('setSmartStartDate', cookieStart);
                commit('setSmartEndDate', cookieEnd);
            } else {
                let today = dayjs();
                commit('setSmartStartDate', (today.day() === 1
                    ? dayjs().subtract(3, 'day')
                    : dayjs().subtract(1, 'day')).format('YYYY-MM-DD'));
                const nextSunday = today.day(7);
                commit('setSmartEndDate', nextSunday.add(7, 'day').format('YYYY-MM-DD'));
            }
            // Load auto refresh prompt
            if (rootState.auth.user) {
                const options = {
                    accessTokenFactory: () => {
                        return `${rootState.auth.user.token}`;
                    },
                };
                let connection = new signalR.HubConnectionBuilder()
                    .withUrl(process.env.VUE_APP_API_URL + "hubs/smart", options)
                    .build();
                connection.start().catch(error => { });
                connection.on("RefreshRequired", data => {
                    commit('setRefreshRequired', rootState.auth.user.email != data);
                });
                connection.on('finished', (update) => {
                    connection.stop();
                });
            }
            let smartLock = Cookies.get('smart-lock');
            if (typeof smartLock !== 'undefined') {
                commit('setSmartLock', smartLock == 'true');
            }
            dispatch('refreshSmartData');
        },
        toggleSmartLock({ commit, state }, lock) {
            commit('setSmartLock', lock);
            Cookies.set('smart-lock', lock);
        },
        refreshSmartData({ commit, dispatch, state }) {
            commit('setLoadingData', true);
            api.get('/dashboard/smartjobs?startDate=' + state.smartStartDate + "&endDate=" + state.smartEndDate + "&hideArchived=" + state.hideArchived)
                .then(res => {
                    commit('setLoadingData', false);
                    commit('setRefreshRequired', false);
                    dispatch('loadSmartData', res.data);
                    dispatch('loadSmartColumns');
                }).catch(error => {
                    commit('setLoadingData', false);
                    dispatch('showErrorSnack', "We ran into an issue...");
                });
        },
        loadSmartColumns({ commit, state }) {
            state.smartData.forEach(column => {
                let cookieName = 'smart-column-expand-' + column.status;
                if (column.statusDate) {
                    cookieName += '-' + column.statusDate.substring(0, 10);
                }
                let cookieExpand = Cookies.get(cookieName);
                if (typeof cookieExpand !== 'undefined') {
                    column.expand = cookieExpand == 'true';
                    commit('updateSmartColumn', column);
                }
            });
        },
        expandSmartColumn({ commit, state }, column) {
            column.expand = !column.expand;
            commit('updateSmartColumn', column);
            let cookieName = 'smart-column-expand-' + column.status;
            if (column.statusDate) {
                cookieName += '-' + column.statusDate.substring(0, 10);
            }
            Cookies.set(cookieName, column.expand, { expires: 7 });
        },
        saveSmartDates({ dispatch, state }) {
            if (state.smartStartDate) {
                Cookies.set('smart-start-date', state.smartStartDate, { expires: 0.4 });
            }
            if (state.smartEndDate) {
                Cookies.set('smart-end-date', state.smartEndDate, { expires: 0.4 });
            }
            dispatch('refreshSmartData');
        },
        loadSmartData({ commit, dispatch }, data) {
            commit('setShowJobDialog', false);
            commit('setShowJobCompleteDialog', false);
            commit('setShowJobReworkDialog', false);
            commit('setSmartData', data);
            dispatch('loadSmartColumns');
        },
        openSmartJobDialog({ commit, dispatch }, job) {
            commit('setDialogJob', job);
            dispatch('initNotes', { classId: job.id, className: 'Job'});
            commit('setShowJobDialog', true);
        },
        openSmartJobCompleteDialog({ commit }) {
            commit('setShowJobCompleteDialog', true);
            commit('setPartiallyComplete', false);
        },
        openSmartJobCompletePartialDialog({ commit }) {
            commit('setShowJobCompleteDialog', true);
            commit('setPartiallyComplete', true);
        },
        openJobReworkDialog({ commit }, job) {
            // Build dialog job
            commit('setDialogJob', {
                taskDate: dayjs().format('YYYY-MM-DD'),
                jobId: job.id,
                taskSubTotal: job.activeTask.estimatedSubtotalCompleted ? job.activeTask.estimatedSubtotalCompleted : job.outstandingSubtotal,
                jobItems: job.jobItems
            });
            commit('setShowJobReworkDialog', true);
        },
        openSmartJobReworkDialog({ commit }) {
            commit('setShowJobReworkDialog', true);
        },
        updateSmartJob({ commit, dispatch, state }, { path, value }) {
            let updateJob = state.dialogJob;
            api.patch(entities.Job.ROUTE + updateJob.jobId, [{
                op: "replace",
                path: "/" + path,
                value: value
            }]).then(res => {
                updateJob[path] = value;
                commit('updateDialogJob', updateJob);
                dispatch('showSuccessSnack', 'Job updated!');
                return;
            }).catch(error => {
                dispatch('showErrorSnack', "We ran into an issue...");
            });
        },
        updateSmartJobStatus({ dispatch, state }, { job, status, taskId, order, date }) {
            let formData = new FormData();
            formData.append('id', job.id);
            formData.append('status', status);
            if (taskId) {
                formData.append('taskId', taskId);
                formData.append('order', order);
                if (date !== null) {
                    formData.append('date', date);
                }
            }
            if (state.smartStartDate)
            {
                formData.append('startDate', state.smartStartDate);
            }
            if (state.smartStartDate) {
                formData.append('endDate', state.smartEndDate);
            }
            api.post(entities.Job.ROUTE + 'updatestatus', formData)
                .then(res => {
                    dispatch('loadSmartData', res.data);
                })
                .catch(error => {
                    dispatch('showErrorSnack', "We ran into an issue...");
                });
        },
        openSmartPretreated({ commit }, job) {
            commit('setShowJobPretreatmentDialog', true);
            commit('setPretreatmentJob', job);
        },
        saveSmartPretreated({ commit, dispatch, state }, { job, date, location, supervisor }) {
            let formData = new FormData();
            formData.append('id', job.id);
            formData.append('date', date);
            formData.append('location', location);
            formData.append('supervisor', supervisor);
            commit('setPretreatSaving', true);
            api.post(entities.Job.ROUTE + 'markpretreated', formData)
                .then(res => {
                    commit('setPretreatSaving', false);
                    commit('setShowJobPretreatmentDialog', false);
                    commit('setPretreatmentJob', null);
                    if (state.pretreatmentTableData) {
                        dispatch('loadPretreatmentTableData');
                    } else {
                        job.datePretreatment = date == '' ? null : date;
                        commit('updateDialogJob', job);
                    }
                    dispatch('showSuccessSnack', 'Job updated!');
                })
                .catch(error => {
                    commit('setPretreatSaving', false);
                    dispatch('showErrorSnack', "We ran into an issue...");
                });
        },
        updateSmartReceived({ dispatch, state }) {
            dispatch('updateSmartJob', { path: 'dateReceived', value: (!state.dialogJob.dateReceived ? dayjs().format('YYYY-MM-DD') : null) });
        },
        loadPretreatmentTableData({ commit, dispatch }) {
            commit('setPretreatmentTableDataLoading', true);
            api.get('dashboard/pretreatment')
                .then(res => {
                    commit('setPretreatmentTableDataLoading', false);
                    commit('setPretreatmentTableData', res.data);
                }).catch(error => {
                    commit('setPretreatmentTableDataLoading', true);
                    dispatch('showErrorSnack', 'There was an issue getting the jobs');
                });
        },
        showBulkSchedule({ commit, dispatch, getters }) {
            commit('setBulkUpdateJobs', getters.smartScheduleJobs);
            commit('setShowBulkSchedule', true);
        }
    },
    getters: {
        smartScheduleJobs(state) {
            if (state && state.smartData && state.smartData.length > 0) {
                return state.smartData.find(column => column.status == jobs.JOBSTAT_SCHEDULE).jobs.sort((a, b) => {
                    let numA = parseInt(a.name.split('-')[1], 10);
                    let numB = parseInt(b.name.split('-')[1], 10);
                    return numA - numB;
                });
            }
            return [];
        }
    }
}