<template>
    <div>
        <div class="d-flex">
            <div class="flex-1-0">
                <h1>General</h1>
                <p class="mb-2">Update general settings of the system.</p>
            </div>
        </div>
        <v-card flat>
            <v-card-text>
                <v-sheet border rounded  color="grey-lighten-2" v-for="section in rateSections" :key="section.header" class="rate-section">
                    <h3>{{ section.header }}</h3>
                    <p>{{ section.text }}</p>
                    <div v-for="rate in section.rates" :key="rate">
                        <rates-display :type="rate" :class-id="0" class-name="Product"></rates-display>
                    </div>
                </v-sheet>
            </v-card-text>
        </v-card>
    </div>
</template>
<style scoped>
    .rate-section {
        margin-bottom:10px;
        padding: 10px 5px 10px 5px;
    }
</style>
<script>
    function initialState() {
        return {
            saving: false,
            loading: false,
            rateSections:[]
        };
    }
    export default {
        name: "SettingsGeneralView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.rateSections = [
                {
                    header: 'Global Pricing',
                    text: 'This is the global SQM pricing rate that is applied to all products by default.',
                    rates:[
                        this.$constants.rates.RATE_SQMPRICING,
                    ]
                },
                /*
                {
                    header: 'Finishing Multipliers',
                    text: 'This is the global multipliers for finishing work.',
                    rates:[
                        this.$constants.rates.RATE_BLAST,
                        this.$constants.rates.RATE_STRIP,
                    ]
                },
                {
                    header: 'Thickness Pricing Multipliers',
                    text: 'These thickness multipliers are applied to products. Up to 4mm is 1.0x and excluded.',
                    rates:[
                        this.$constants.rates.RATE_THICK4TO6,
                        this.$constants.rates.RATE_THICK7TO9,
                        this.$constants.rates.RATE_THICK10
                    ]
                },
                {
                    header: 'Complexity Pricing Multipliers',
                    text: 'These complexity multipliers are applied to products. Simple is 1.0x and excluded.',
                    rates:[
                        this.$constants.rates.RATE_COMSTA,
                        this.$constants.rates.RATE_COMCOM,
                    ]
                },*/
            ];
        },
        props: {
        },
        methods: {
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
        }
    };
</script>