import entities from '../../constants/entities';
import api from '../../helpers/api';
export const products = {
    state: {
        contactProductsLoading: false,
        contactProducts: [],
        draftCreateJob: null,
        productRates: null,
        productRatesLoading: false
    },
    mutations: {
        setContactProductsLoading(state, loading) {
            state.contactProductsLoading = loading;
        },
        setContactProducts(state, contactProducts) {
            state.contactProducts = contactProducts;
        },
        setProductRates(state, rates) {
            state.productRates = rates;
        },
        setProductRatesLoading(state, loading) {
            state.productRatesLoading = loading;
        }
    },
    actions: {
        loadProductRates({ commit, dispatch }) {
            commit("setProductRatesLoading", true);
            api.get("rates/currentpricing").then(res => {
                commit("setProductRatesLoading", false);
                commit("setProductRates", res.data);
            })
                .catch(error => {
                    commit("setProductRatesLoading", false);
                    dispatch("showErrorSnack", 'Cannot load product rates');
                });
        },
        loadContactProducts({ commit, dispatch }, contactId) {
            commit("setContactProductsLoading", true);
            api.get(entities.Product.ROUTE + "contactvalidproducts?contactid=" + contactId).then(res => {
                commit("setContactProductsLoading", false);
                commit("setContactProducts", res.data);
            })
            .catch(error => {
                commit("setContactProductsLoading", false);
                dispatch("showErrorSnack", 'Cannot load contacts products');
            });
        },
    },
    getters: {
    }
}