<template>
    <equation-display :equation="equationDisplay"></equation-display>
</template>
<style scoped>
</style>
<script>
    import { getColourFromInt } from '../../mixins/colourMixins'
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "ProductUomDisplay",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            uomType: String,
            customPricing: Boolean
        },
        methods: {
        },
        computed: {
            equationDisplay() {
                let uom = this.$constants.products.uoms.find(item => item.id == this.uomType); 
                return this.customPricing ? uom.customEquation : uom.equation;
            }
        }
    };
</script>