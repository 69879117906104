<template>
    <div>
        <strong class="panel-section-header mt-0">
            Details
        </strong>
        <v-table density="compact" class="text-caption">
            <tbody>
                <tr align="right">
                    <th>Type</th>
                    <td><type-chip :type="entityRecord.type" entity="Product"></type-chip></td>
                </tr>
                <tr align="right">
                    <th>Code</th>
                    <td>{{ entityRecord.code }}</td>
                </tr>
                <tr v-if="entityRecord.contact" align="right">
                    <th>Customer</th>
                    <td><contact-link :contact="entityRecord.contact"></contact-link></td>
                </tr>
            </tbody>
        </v-table>
        <strong class="panel-section-header mt-2">
            UOM
        </strong>
        <v-table density="compact" class="text-caption">
            <tbody>
                <tr align="right">
                    <th class="w-30">Type</th>
                    <td>
                        {{ entityRecord.uomType }}
                    </td>
                </tr>
                <tr v-if="entityRecord.pricingRate" align="right">
                    <th>Rate</th>
                    <td>${{ entityRecord.pricingRate }}</td>
                </tr>
                <tr v-if="entityRecord.periphery" align="right">
                    <th>Periphery</th>
                    <td>{{ entityRecord.periphery }} MM</td>
                </tr>
                <tr v-if="entityRecord.length" align="right">
                    <th>Length</th>
                    <td>{{ entityRecord.length }} MM</td>
                </tr>
                <tr v-if="entityRecord.width" align="right">
                    <th>Width</th>
                    <td>{{ entityRecord.width }} MM</td>
                </tr>
                <tr v-if="entityRecord.height" align="right">
                    <th>Height</th>
                    <td>{{ entityRecord.height }} MM</td>
                </tr>
            </tbody>
        </v-table>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false
        };
    }
    export default {
        name: "ProductDetailsPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
        }
    };
</script>