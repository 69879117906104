<template>
    <div :key="smartKey">
        <v-overlay :model-value="loadingData"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="smart-controls d-flex">
            <div class="date-controls">
                <label><v-icon icon="mdi-gamepad-square"></v-icon></label>
                <v-text-field label="Start Date"
                              type="date"
                              density="compact"
                              @change="saveSmartDates"
                              :hide-details="true"
                              v-model="smartStartDate"></v-text-field>
                <v-text-field label="End Date"
                              type="date"
                              density="compact"
                              @change="saveSmartDates"
                              :hide-details="true"
                              v-model="smartEndDate"></v-text-field>
                <v-tooltip text="Hide Yesterday">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" v-if="yesterdayShowing" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="hideYesterday" icon="mdi-update">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Refresh">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="refreshSmartData" icon="mdi-refresh">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Hide Archived">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="toggleHideArchived" icon="mdi-eye-off-outline" :active="hideArchived">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Show Colours">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" :active="showColours" variant="outlined" class="refresh-btn d-none d-md-inline-block" @click="toggleShowColours" icon="mdi-palette">
                        </v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Lock Drag">
                    <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" color="primary" class="refresh-btn d-block d-md-none" variant="outlined" :icon="!smartLock ? 'mdi-lock-open' : 'mdi-lock'" @click="toggleSmartLock(!smartLock)">
                        </v-btn>
                    </template>
                </v-tooltip>
            </div>
            <div class="job-search d-none d-md-inline-block">
                <v-text-field label="Search cards..."
                              prepend-icon="mdi-magnify"
                              v-model="searchTerm"
                              density="compact"
                              :hide-details="true"
                              clearable></v-text-field>
            </div>
            <div class="d-none d-md-inline-block">
                <v-btn-toggle variant="outlined"
                              divided>
                    <v-btn variant="outlined"
                           prepend-icon="mdi-printer"
                           class="refresh-btn"
                           to="/dashboard/dayprogram"
                           target="_blank">
                        Program
                    </v-btn>
                    <v-btn variant="outlined"
                           prepend-icon="mdi-printer"
                           class="refresh-btn"
                           to="/dashboard/powderreport"
                           target="_blank">
                        Powder
                    </v-btn>
                </v-btn-toggle>
            </div>
        </div>
        <div class="smart-cont" ref="smartCont">
            <div class="smart-area smart-area-schedule">
                <smart-column :column="scheduleColumn" :show-colours="showColours" :search-term="searchTerm" ></smart-column>
            </div>
            <div class="smart-area smart-area-days" ref="smartTable">
                <div class="smart-table">
                    <smart-column v-for="column in filteredColumns" :key="column.name" :column="column" :show-colours="showColours" :search-term="searchTerm"></smart-column>
                </div>
            </div>
        </div>
        <smart-job-dialog></smart-job-dialog>
        <smart-job-complete-dialog></smart-job-complete-dialog>
        <smart-bulk-schedule-dialog></smart-bulk-schedule-dialog>
        <v-snackbar v-model="refreshRequired"
                    color="blue-darken-3"
                    :timeout="-1"
                    location="bottom"
                    elevation="24"
                    vertical>
            <div class="text-subtitle-1 pb-2">Changes have been detected</div>

            <p>Please refresh the data to view changes.</p>

            <template v-slot:actions>
                <v-btn color="blue-lighten-5"
                       variant="tonal"
                       prepend-icon="mdi-refresh"
                       @click="refreshSmartData">
                    Refresh Jobs
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<style scoped lang="scss">
    @import '../../styles/smart.scss'
</style>
<script>
    /*
    Blue - Pretreatment Completed
    Red - Rework
    */
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas } from "../../mixins/numberMixins";
    import Cookies from 'js-cookie';
    function initialState() {
        return {
            searchTerm: '',
            showColours: false,
            dragOptions: {
                animation: 200,
                group: "jobs",
                disabled: false,
                ghostClass: "smart-ghost",
            }
        };
    }
    export default {
        name: "SmartDashboard",
        data: function () {
            return initialState();
        },
        mounted: function () {
            window.addEventListener("scroll", this.onScroll, true);
            document.addEventListener('drag', this.trackDragging);
            let colourExpand = Cookies.get('smart-show-colours');
            if (typeof colourExpand !== 'undefined') {
                this.showColours = colourExpand == 'true';
            }
            this.initSmart();
        },
        beforeUnmount: function () {
            window.removeEventListener("scroll", this.onScroll, true);
            document.removeEventListener("drag", this.trackDragging);
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "refreshSmartData",
                "updateSmartJobStatus",
                "expandSmartColumn",
                "saveSmartDates",
                "initSmart",
                "showBulkSchedule",
                "toggleSmartLock"
            ]),
            onScroll(e) {
                this.smartScrollPos = e.target.scrollTop;
                this.smartScrollLeft = e.target.scrollLeft;
            },
            trackDragging(e) {
                const scrollContainer = this.$refs.smartTable;
                if (!scrollContainer) return;

                const { left, right, width } = scrollContainer.getBoundingClientRect();
                const scrollSpeed = 15; // Base scroll speed

                // Calculate the distance from the left and right edges
                const distanceFromLeft = e.x - left;
                const distanceFromRight = right - e.x;

                // If near the left edge
                if (distanceFromLeft < 100) {
                    // Increase scroll speed based on proximity to the left edge
                    const speedFactor = 1 + (100 - distanceFromLeft) / 100; // Range: 1x to 2x speed
                    scrollContainer.scrollLeft -= scrollSpeed * speedFactor;
                }
                // If near the right edge
                else if (distanceFromRight < 100) {
                    // Increase scroll speed based on proximity to the right edge
                    const speedFactor = 1 + (100 - distanceFromRight) / 100; // Range: 1x to 2x speed
                    scrollContainer.scrollLeft += scrollSpeed * speedFactor;
                }
            },
            numberWithCommas,
            toggleShowColours() {
                this.showColours = !this.showColours;
                 Cookies.set('smart-show-colours', this.showColours);
            },
            hideYesterday() {
                this.smartStartDate = this.$dayjs().format('YYYY-MM-DD');
                this.saveSmartDates();
            },
            toggleHideArchived() {
                this.hideArchived = !this.hideArchived;
                this.refreshSmartData();
            },
            columnTotal(column) {
                if (column.jobs.length > 0){
                    return column.jobs.reduce((acc, obj) => acc + obj.taskSubTotal, 0);
                }
                return 0;
            },
            columnColourTotal(colour, column) {
                return column.jobs.filter(job => job.colours.replace('Duralloy ', '') === colour).length;
            },
            columnColours(column){
                if (column.jobs.length > 0){
                    return [...new Set(column.jobs
                      .filter(item => item?.colours) // Filter out null, undefined, and empty strings
                      .map(item => item.colours.replace('Duralloy ', ''))     // Extract the colours property
                    )].sort();
                }
                return '';
            },
            updateCard(column, event) {
                let newIndex = null; 
                let job = null;
                if (event.moved && event.moved.element) {
                    newIndex = event.moved.newIndex;
                    job = event.moved.element;
                }
                if (event.added && event.added.element) {
                    newIndex = event.added.newIndex;
                    job = event.added.element;
                }
                if (newIndex != null && job != null) {
                    this.updateSmartJobStatus({ job: job, status: column.status, taskId: job.jobTaskId, order: newIndex, date: column.statusDate });
                }
            },
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
                loadingData: (state) => state.smart.loadingData,
                smartData: (state) => state.smart.smartData,
                smartKey: (state) => state.smart.smartKey,
            }),
            hideArchived: {
                get() {
                    return this.$store.state.smart.hideArchived
                },
                set(value) {
                    this.$store.commit('setSmartHideArchived', value)
                }
            },
            smartScrollPos: {
                get() {
                    return this.$store.state.smart.smartScrollPos
                },
                set(value) {
                    this.$store.commit('setSmartScrollPos', value)
                }
            },
            smartScrollLeft: {
                get() {
                    return this.$store.state.smart.smartScrollLeft
                },
                set(value) {
                    this.$store.commit('setSmartScrollLeft', value)
                }
            },
            smartLock: {
                get() {
                    return this.$store.state.smart.smartLock
                },
                set(value) {
                    this.$store.commit('setSmartLock', value)
                }
            },
            refreshRequired: {
                get() {
                    return this.$store.state.smart.refreshRequired
                },
                set(value) {
                    this.$store.commit('setRefreshRequired', value)
                }
            },
            smartStartDate: {
                get() {
                    return this.$store.state.smart.smartStartDate
                },
                set(value) {
                    this.$store.commit('setSmartStartDate', value)
                }
            },
            smartEndDate: {
                get() {
                    return this.$store.state.smart.smartEndDate
                },
                set(value) {
                    this.$store.commit('setSmartEndDate', value)
                }
            },
            scheduleColumn() {
                if (this.smartData) {
                    return this.smartData.find(column => column.name == "Schedule");
                }
                return null;
            },
            filteredColumns() {
                if (this.smartData) {
                    let columns = this.smartData.filter(column => column.name != "Schedule");
                    if (this.searchTerm) {
                        // Filter the columns
                        let upSearchTerm = this.searchTerm.toUpperCase();
                        return columns.map(column => ({
                            ...column, // Keep the column object unchanged
                            jobs: column.jobs.filter(job =>
                                job.name.toUpperCase().includes(upSearchTerm) ||
                                (job.customerNumber && job.customerNumber.toUpperCase().includes(upSearchTerm)) ||
                                (job.colours && job.colours.toUpperCase().includes(upSearchTerm)) ||
                                (job.contact && job.contact.name.toUpperCase().includes(upSearchTerm)))
                        }));
                    } else {
                        return columns
                    }
                }
                return this.smartData;
            },
            yesterdayShowing() {
                const today = this.$dayjs();
                return this.$dayjs(this.smartStartDate).date() == today.subtract(1, 'day').date();
            },
        },
        watch: {
            smartKey: {
                handler: function () {
                    this.$nextTick(() => {
                        if (this.smartScrollPos) {
                            this.$refs.smartCont.scrollTop = this.smartScrollPos;
                        }
                        if (this.smartScrollLeft) {
                            this.$refs.smartTable.scrollLeft = this.smartScrollLeft;
                        }
                    });
                }
            },
        }
    };
</script>