<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-row :key="key">
            <v-col cols="10">
                <v-stepper v-model="step" :items="steps">
                    <template v-slot:[`item.1`]>
                        <v-card :title="entityContact.SINGULAR" flat>
                            <v-card-subtitle>
                                Find the {{ entityContact.SINGULAR }} who this job is for. If they are new, then add.
                            </v-card-subtitle>
                            <v-card-text>
                                <div class="customer-input">
                                    <v-text-field :label="'Search for the '+entityContact.SINGULAR+'...'" v-model="contactSearchTerm" :loading="contactSearching"></v-text-field>
                                    <v-list lines="one">
                                        <v-list-item v-for="option in contactSearchResults"
                                                     :key="option.id"
                                                     :value="option"
                                                     :active="option == editJob.contact"
                                                     @click="selectContact(option)"
                                                     color="primary">
                                            <template v-slot:prepend>
                                                <v-icon :icon="option == editJob.contact ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"></v-icon>
                                            </template>
                                            <template v-slot:title>
                                                <span v-html="formatSearchName(option.name, contactSearchTerm)"></span>
                                            </template>
                                            <template v-slot:subtitle>
                                                <div><i>Email: </i><span v-html="formatSearchName(option.accountsEmail, contactSearchTerm)"></span></div>
                                                <div><i>Phone: </i><span v-html="formatSearchName(option.phone, contactSearchTerm)"></span></div>
                                            </template>
                                        </v-list-item>
                                    </v-list>
                                    <div v-if="contactSearchTerm.length > 0 && !contactSearching" class="align-center justify-center">
                                        <p class="mb-3">Can't find the {{ entityContact.SINGULAR }}?</p>
                                        <v-btn color="primary"
                                               @click="showAddContact"
                                               prepend-icon="mdi-plus">
                                            ADD {{ entityContact.SINGULAR }}
                                        </v-btn>
                                    </div>
                                    <div v-if="editJob.contact">
                                        <hr class="my-2" />
                                        <h3 v-if="!editJob.contact.id">New {{ entityContact.SINGULAR }}</h3>
                                        <h3 v-if="editJob.contact.id">Editing {{ entityContact.SINGULAR }}</h3>
                                        <contact-input v-model:contact="editJob.contact" :small="true"></contact-input>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:[`item.2`]>
                        <v-card title="Details" flat>
                            <v-card-subtitle>
                                Enter all the detail's you have for this job.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-text-field label="Customer Number"
                                              v-model="editJob.customerNumber"
                                              hint="Enter the customers PO number or any other customer identified number"
                                              persistent-hint></v-text-field>
                                <v-text-field label="Estimated Receive Date"
                                              class="mt-2"
                                              type="date"
                                              v-model="editJob.dateReceiveEstimate"></v-text-field>
                                <v-checkbox v-model="editJob.warrantyRequired"
                                    hide-details
                                    density="compact"
                                    label="Warranty Required?"></v-checkbox>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:[`item.3`]>
                        <v-card title="Charges" flat>
                            <v-card-subtitle>
                                Enter the charges for this job. Try and use existing product definitions if it is an unknown.
                            </v-card-subtitle>
                            <v-card-text>
                                <v-sheet class="mb-3 pa-3" rounded elevation="4" color="blue-grey-lighten-4">
                                    <p>Set new line defaults below</p>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-combobox v-model="defaultColour"
                                                        density="compact"
                                                        :loading="coloursSelectLoading"
                                                        :items="coloursSelect"
                                                        hide-details
                                                        item-title="selectName"></v-combobox>
                                        </v-col>
                                        <v-col cols="6">
                                            <div class="d-flex">
                                                <v-checkbox-btn class="mt-3" label="Default Blast?" v-model="defaultBlast" density="compact"></v-checkbox-btn>
                                                <v-checkbox-btn class="mt-3" label="Default Strip?" v-model="defaultStrip" density="compact"></v-checkbox-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                                <v-table class="charges-table">
                                    <thead>
                                        <tr>
                                            <th class="text-center" style="width:5%!important"></th>
                                            <th class="text-left" style="width:25%!important">
                                                Product
                                            </th>
                                            <th class="text-left" style="width: 10% !important">
                                                UOM
                                            </th>
                                            <th class="text-left" style="width: 25% !important">
                                                Colour
                                            </th>
                                            <th class="text-center" style="width: 2% !important">
                                                Blast?
                                            </th>
                                            <th class="text-center" style="width: 2% !important">
                                                Strip?
                                            </th>
                                            <th class="text-right" style="width: 4% !important">
                                                QTY
                                            </th>
                                            <th class="text-right" style="width: 6% !important">
                                                Unit
                                            </th>
                                            <th class="text-right" style="width: 6% !important">
                                                Total
                                            </th>
                                            <th style="width: 5% !important"></th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(line, index) in editJob.jobItems" :key="line.order">
                                        <tr>
                                            <td>
                                                <v-icon class="draggable" icon="mdi-reorder-horizontal"></v-icon>
                                            </td>
                                            <td>
                                                <v-combobox v-model="line.product"
                                                            density="compact"
                                                            :rules="[$rules.required]"
                                                            variant="plain"
                                                            hide-details
                                                            @update:modelValue="productChange(line)"
                                                            :loading="contactProductsLoading"
                                                            :items="contactProducts"
                                                            item-title="name"></v-combobox>
                                                <v-text-field :label="line.product ? 'Additional Description' : 'Description'"
                                                              density="compact"
                                                              v-model="line.additionalDescription"
                                                              variant="plain"
                                                              class="small-field"
                                                              hide-details></v-text-field>
                                                <v-chip v-if="line.product && line.product.pricingRate"
                                                        color="light-green-lighten-2"
                                                        class="mb-1"
                                                        variant="flat"
                                                        density="comfortable"
                                                        size="x-small">${{ numberWithCommas(line.product.pricingRate, 2) }} {{ uomPerDisplay(line.product) }}</v-chip>
                                                <v-chip v-if="!line.product"
                                                        color="warning"
                                                        class="mb-1"
                                                        variant="flat"
                                                        density="comfortable"
                                                        size="x-small">Custom Line</v-chip>
                                            </td>
                                            <td>
                                                <div>
                                                    <div v-if="lineProductField(line, 'uomType') == $constants.products.UOM_PERI" class="d-flex">
                                                        <v-text-field label="Length"
                                                                      density="compact"
                                                                      v-model.number="line.length"
                                                                      variant="plain"
                                                                      class="small-field"
                                                                      hide-details
                                                                      :disabled="lineProductField(line, 'fixedSize')"
                                                                      @update:modelValue="loadPricing(line)"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                    </div>
                                                    <div v-if="lineProductField(line, 'uomType') == $constants.products.UOM_LENG">
                                                        <v-text-field label="Length"
                                                                      density="compact"
                                                                      v-model.number="line.length"
                                                                      variant="plain"
                                                                      class="small-field"
                                                                      hide-details
                                                                      :disabled="lineProductField(line, 'fixedSize')"
                                                                      @update:modelValue="loadPricing(line)"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                    </div>
                                                    <div v-if="lineProductField(line, 'uomType') == $constants.products.UOM_SQM" class="d-flex">
                                                        <v-text-field label="Width"
                                                                      density="compact"
                                                                      v-model.number="line.width"
                                                                      variant="plain"
                                                                      class="small-field"
                                                                      hide-details
                                                                      :disabled="lineProductField(line, 'fixedSize')"
                                                                      @update:modelValue="loadPricing(line)"
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                        <v-text-field label="Height"
                                                                      density="compact"
                                                                      v-model.number="line.height"
                                                                      variant="plain"
                                                                      :disabled="lineProductField(line, 'fixedSize')"
                                                                      @update:modelValue="loadPricing(line)"
                                                                      class="ml-2"
                                                                      hide-details
                                                                      type="number"
                                                                      reverse
                                                                      prefix="MM"></v-text-field>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <v-combobox v-model="line.colour"
                                                            density="compact"
                                                            :loading="coloursSelectLoading"
                                                            variant="plain"
                                                            hide-details
                                                            :items="coloursSelect"
                                                            @update:modelValue="loadPricing(line)"
                                                            item-title="selectName"></v-combobox>
                                                <v-chip v-if="line.colour && line.colour.colourGroupId && line.product"
                                                        color="info"
                                                        class="mb-1"
                                                        variant="flat"
                                                        density="comfortable"
                                                        size="x-small">{{ line.colour.colourGroupName }} ({{ getRate(productRates, $constants.rates.RATE_MULTI, line.colour.colourGroupId) }}%)</v-chip>
                                            </td>
                                            <td class="text-center">
                                                <v-checkbox-btn v-model="line.blast"
                                                                variant="plain"
                                                                density="compact"
                                                                hide-details
                                                                @update:modelValue="loadPricing(line)"></v-checkbox-btn>
                                            </td>
                                            <td class="text-center">
                                                <v-checkbox-btn v-model="line.strip"
                                                                variant="plain"
                                                                density="compact"
                                                                hide-details
                                                                @update:modelValue="loadPricing(line)"></v-checkbox-btn>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              @update:modelValue="loadPricing(line)"
                                                              reverse
                                                              v-model.number="line.quantity"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              v-if="!line.product"
                                                              hide-details
                                                              suffix="$"
                                                              reverse
                                                              v-model="line.unitPrice"></v-text-field>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              v-if="line.product"
                                                              hide-details
                                                              suffix="$"
                                                              disabled
                                                              reverse
                                                              :model-value="numberWithCommas(line.unitPrice, 2)"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              disabled
                                                              suffix="$"
                                                              reverse
                                                              :model-value="numberWithCommas(line.unitPrice * line.quantity, 2)"></v-text-field>
                                            </td>
                                            <td class="text-right">
                                                <v-btn-group divided
                                                             variant="outlined">
                                                    <v-tooltip location="top" text="Copy Line">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn icon="mdi-content-copy"
                                                                   @click="copyLine(line)"
                                                                   v-bind="props"
                                                                   size="x-small">
                                                            </v-btn>
                                                        </template>
                                                    </v-tooltip>
                                                    <v-tooltip location="top" text="Delete Line">
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn icon="mdi-trash-can-outline"
                                                                   @click="deleteLine(index)"
                                                                   v-bind="props"
                                                                   size="x-small">
                                                            </v-btn>
                                                        </template>
                                                    </v-tooltip>
                                                </v-btn-group>
                                            </td>
                                        </tr>
                                        <tr v-if="line.blast" class="bg-grey-lighten-5">
                                            <td colspan="6" class="text-right">Blast Charge</td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              reverse
                                                              disabled
                                                              v-model.number="line.quantity"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              suffix="$"
                                                              reverse
                                                              v-model="line.blastPrice"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              disabled
                                                              suffix="$"
                                                              reverse
                                                              :model-value="numberWithCommas(line.blastPrice * line.quantity, 2)"></v-text-field>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr v-if="line.strip" class="bg-grey-lighten-5">
                                            <td colspan="6" class="text-right">Strip Charge</td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              reverse
                                                              disabled
                                                              v-model.number="line.quantity"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              suffix="$"
                                                              reverse
                                                              v-model="line.stripPrice"></v-text-field>
                                            </td>
                                            <td>
                                                <v-text-field variant="plain"
                                                              density="compact"
                                                              hide-details
                                                              disabled
                                                              suffix="$"
                                                              reverse
                                                              :model-value="numberWithCommas(line.stripPrice * line.quantity, 2)"></v-text-field>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td colspan="10">
                                                <v-btn prepend-icon="mdi-format-list-group-plus"
                                                       @click="addLine(line)"
                                                       block
                                                       size="small">
                                                    ADD LINE
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="total-subline">
                                            <td colspan="8" class="text-right"><h4 class="pr-2">TOTAL ITEMS</h4></td>
                                            <td class="text-right">
                                                <h4>{{ quantityTotal }}</h4>
                                            </td>
                                            <td colspan="3"></td>
                                        </tr>
                                        <tr class="total-subline">
                                            <td colspan="8" class="text-right"><h4 class="pr-2">SUBTOTAL</h4></td>
                                            <td class="text-right">
                                                <h3>${{ numberWithCommas(subTotals, 2) }}</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="total-subline">
                                            <td colspan="8" class="text-right"><h4 class="pr-2">TOTAL GST 15%</h4></td>
                                            <td class="text-right">
                                                <h3>${{ numberWithCommas(taxTotals, 2) }}</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="total-line">
                                            <td colspan="8" class="text-right"><h3 class="pr-2">TOTAL</h3></td>
                                            <td class="text-right">
                                                <h3>${{ numberWithCommas(totals, 2) }}</h3>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </tfoot>
                                </v-table>
                            </v-card-text>
                        </v-card>
                    </template>
                    <template v-slot:actions>
                        <v-stepper-actions @click:next="moveNext" @click:prev="movePrevious"
                                           :disabled="stepperActionsDisabled"></v-stepper-actions>
                    </template>
                </v-stepper>
            </v-col>
            <v-col cols="2">
                <v-card title="Job Details">
                    <v-card-subtitle>
                        Review the details of the job below before saving.
                    </v-card-subtitle>
                    <v-card-text>
                        <v-list lines="one">
                            <v-list-item v-if="editJob.contact" :title="steps[0]" :subtitle="editJob.contact.name">
                                <div><i>Email: </i>{{ editJob.contact.accountsEmail }}</div>
                                <div><i>Phone: </i>{{ editJob.contact.phone }}</div>
                            </v-list-item>
                            <v-list-item v-if="editJob.name" title="Name" :subtitle="name"></v-list-item>
                            <v-list-item v-if="editJob.customerNumber" title="Customer Number" :subtitle="editJob.customerNumber"></v-list-item>
                            <v-list-item v-if="editJob.dateReceiveEstimate" title="Estimated Receive Date" :subtitle="$dayjs(editJob.dateReceiveEstimate).format('DD/MM/YYYY')"></v-list-item>
                        </v-list>
                        <v-alert v-if="saveValid"
                                 type="success"
                                 title="Ready to Save"
                                 text="Job is ready to be saved. Click the save button below."
                                 class="my-2">
                        </v-alert>
                        <v-alert density="compact"
                                 v-if="errors"
                                 type="warning"
                                 class="my-2"
                                 :title="errors.title">
                            <ul>
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                            </ul>
                        </v-alert>
                        <div class="mt-2 pt-2">
                            <v-btn :loading="loading"
                                   type="submit"
                                   :disabled="!saveValid"
                                   flat
                                   color="primary"
                                   prepend-icon="mdi-floppy">SAVE</v-btn>
                            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>
<style scoped>
    .small-table td, th {
        padding: 0px !important;
        font-size: 10px !important;
    }

    .charges-table td, th {
        padding: 0px 5px !important;
        border: 1px solid #F5F5F5;
    }

    :deep(.v-field) {
        font-size: 0.875rem !important;
    }

    .total-line td {
        border-top: 2px solid black !important;
        border-bottom: 2px solid black;
    }

    .total-subline td {
        box-shadow: inset 0 -1px 0 rgba(var(--v-border-color), var(--v-border-opacity));
    }
    .v-combobox .v-combobox__selection-text {
        overflow: visible!important;
        text-overflow: clip!important;
        white-space: break-spaces!important;
        margin-bottom: 15px!important;
    }
</style>
<script>
    import _cloneDeep from "lodash/cloneDeep";
    import { mapActions, mapState, mapGetters } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    import { numberWithCommas, roundAway2dp } from "../mixins/numberMixins";
    import { calculateJobLineUnitPrice, getRate, uomPerDisplay } from "../mixins/productMixins";
    import tasks from "../constants/tasks";
    //import draggable from 'vuedraggable'
    function initialState() {
        return {
            key: 0,
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            editJob: {
                contact: null,
                jobItems: [],
                customerNumber: '',
                dateReceiveEstimate: null
            },
            step: 1,
            steps: ['Contact', 'Details', 'Charges'],
            contactSearchTerm: "",
            contactSearching: false,
            addNewContactForm: false,
            contactSearchTimer: null,
            contactSearchResults: [],
            contactSearchErrors: null,
            pricingTimer: null,
            defaultColour: null,
            defaultStrip:null,
            defaultBlast:null,
        };
    }
    export default {
        name: "JobForm",
        data: function () {
            return initialState();
        },
        components: {
            //draggable,
        },
        mounted: function () {
            this.loadProductRates();
            this.loadSelectColours();
            if (this.editEntity) {
                this.editJob = _cloneDeep(this.editEntity);
            } else {
                // check for draft
                var tempDraftJob = this.getDraftJob();
                if (tempDraftJob){
                    this.$confirm(
                        {
                            message: `Draft job found (last updated ${tempDraftJob.lastUpdated})! Would you like to load this draft?`,
                            disableKeys: false,
                            auth: false,
                            button: {
                                no: 'No',
                                yes: 'Yes'
                            },
                            callback: confirm => {
                                if (confirm) {
                                    this.editJob = tempDraftJob.job;
                                    this.step = tempDraftJob.step;
                                    if (this.editJob.contact){
                                        this.loadContactProducts(this.editJob.contact.id);
                                    }
                                    this.defaultColour = tempDraftJob.defaultColour;
                                    this.defaultStrip = tempDraftJob.defaultStrip;
                                    this.defaultBlast = tempDraftJob.defaultBlast;
                                } else {
                                    this.removeDraftJob();
                                }
                            }
                        }
                    );
                } else {
                    this.addLine();
                }
            }
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities",
                "loadContactProducts",
                "loadSelectColours",
                "loadJobItemTemplates",
                "loadProductRates"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            getDraftJob() {
                let draft = localStorage.getItem("draftCreateJob");
                if (draft === null) {
                    return null
                } else {
                    return JSON.parse(draft);
                }
            },
            saveDraftJob(){
                localStorage.setItem("draftCreateJob", 
                    JSON.stringify({ 
                        job: this.editJob, 
                        step: this.step, lastUpdated: this.$dayjs().format('hh:mm:ss a, dddd D/M/YYYY'),
                        defaultColour: this.defaultColour,
                        defaultThickness: this.defaultThickness,
                        defaultAllOver: this.defaultAllOver,
                        defaultStrip: this.defaultStrip,
                        defaultBlast: this.defaultBlast
                    }));
            },
            removeDraftJob(){
                localStorage.removeItem("draftCreateJob");
            },
            formatSearchName,
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editEntity != null) {
                    formData.append('id', this.editEntity.id);
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                }
                /*
                formData.append('name', this.name);
                formData.append('customerNumber', this.customerNumber);
                formData.append('dateReceiveEstimate', this.dateReceiveEstimate);
                formData.append('contactId', this.contact.id);
                // build job items
                let t = 0;
                this.editJob.jobItems.forEach(line => {
                    formData.append(`jobItems[${t}].order`, t.toString());
                    formData.append(`jobItems[${t}].quantity`, line.quantity);
                    formData.append(`jobItems[${t}].productId`, line.product.id);
                    if (line.colour) {
                        formData.append(`jobItems[${t}].colourId`, line.colour.id);
                    }
                    if (line.width) {
                        formData.append(`jobItems[${t}].width`, line.width);
                    }
                    if (line.height) {
                        formData.append(`jobItems[${t}].height`, line.height);
                    }
                    if (line.depth) {
                        formData.append(`jobItems[${t}].depth`, line.depth);
                    }
                    if (line.length) {
                        formData.append(`jobItems[${t}].length`, line.length);
                    }
                    t++;
                });
                */
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.removeDraftJob();
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reset();
                        this.showSuccessSnack("Updated!");
                        this.reloadEntities();
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else {
                            this.errors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.loading = false;
                    });
            },
            moveNext() {
                if (this.step == 1 && this.editJob.contact) {
                    // Load contact products
                    this.loadContactProducts(this.editJob.contact.id);
                }
                this.step++;
                this.saveDraftJob();
            },
            movePrevious() {
                this.step--;
                this.saveDraftJob();
            },
            showAddContact() {
                this.addNewContactForm = true;
                this.contactSearchTerm = '';
                this.contactSearching = false;
                this.editJob.contact = {
                    id: null,
                    name: '',
                    accountsEmail: '',
                    phone: '',
                    contactGroup: null,
                    contactPeople: []
                };
            },
            searchContacts() {
                this.contactSearching = true;
                this.$api.get(this.entityContact.ROUTE + "?searchTerm=" + this.contactSearchTerm)
                    .then(res => {
                        this.contactSearching = false;
                        this.contactSearchResults = res.data.items;
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.contactSearchErrors = error.response.data;
                        } else {
                            this.contactSearchErrors = { title: "Whoops", errors: [["We hit an error. Please contact support."]] };
                        }
                        this.contactSearching = false;
                    });
            },
            selectContact(option) {
                if (this.editJob.contact == option) {
                    this.editJob.contact = null;
                } else {
                    this.editJob.contact = option;
                }
                this.saveDraftJob();
            },
            addLine() {
                this.editJob.jobItems.push({
                    order: this.editJob.jobItems.length + 1,
                    quantity: 1,
                    product: null,
                    width: null,
                    height: null,
                    length: null,
                    periphery: null,
                    colour: this.defaultColour,
                    blast: this.defaultBlast,
                    strip: this.defaultStrip,
                    jobTasks: null,
                    additionalDescription: ''
                });
                this.saveDraftJob();
            },
            lineProductField(line, field) {
                if (line.product) {
                    return line.product[field];
                }
                return null;
            },
            copyLine(line) {
                let newLine = _cloneDeep(line);
                this.editJob.jobItems.push(newLine);
                this.saveDraftJob();
            },
            deleteLine(index) {
                this.editJob.jobItems.splice(index, 1);
                if (index == 0) {
                    this.addLine();
                }
                this.saveDraftJob();
            },
            productChange(line) {
                if (line.product){
                    line.additionalDescription = null;
                    line.height = line.product.height;
                    line.width = line.product.width;
                    line.length = line.product.length;
                    line.periphery = line.product.periphery;
                } else {
                    line.height = null;
                    line.width = null;
                    line.length = null;
                    line.periphery = null;
                    line.additionalDescription = null;
                }
            },
            loadPricing(line) {
                if (line.product) {
                    this.$nextTick(() => {
                        line.unitPrice = calculateJobLineUnitPrice(line, this.productRates);
                    });
                }
            },
            numberWithCommas,
            getRate,
            uomPerDisplay,
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
                contactProducts: (state) => state.products.contactProducts,
                contactProductsLoading: (state) => state.products.contactProductsLoading,
                coloursSelect: (state) => state.colours.coloursSelect,
                coloursSelectLoading: (state) => state.colours.coloursSelectLoading,
                productRates: (state) => state.products.productRates,
                productRatesLoading: (state) => state.products.productRatesLoading,
            }),
            ...mapGetters([
                "filterJobItemTemplates",
                "templateJobItemTasks"
            ]),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            entityContact() {
                return this.$constants.entities.Contact;
            },
            stepperActionsDisabled() {
                let prevDisabled = this.step == 1;
                let nextDisabled = this.step == this.steps.length;
                // Stepper action rules
                if (this.step == 1 && !this.editJob.contact) {
                    nextDisabled = true;
                }
                if (this.step == 2 && !this.allChargesValid) {
                    nextDisabled = true;
                }
                if (prevDisabled && nextDisabled) {
                    return true;
                } else if (nextDisabled) {
                    return 'next';
                } else if (prevDisabled) {
                    return 'prev';
                }
                return false;
            },
            saveValid() {
                return this.allChargesValid && this.editJob.contact;
            },
            taxTotals() {
                return roundAway2dp(this.subTotals * 0.15);
            },
            totals() {
                return this.subTotals + this.taxTotals;
            },
            subTotals() {
                let result = 0;
                this.editJob.jobItems.forEach(line => {
                    if (line.unitPrice && line.quantity) {
                        result += line.unitPrice * line.quantity;
                    }
                });
                return result;
            },
            quantityTotal() {
                let result = 0;
                this.editJob.jobItems.forEach(line => {
                    if (line.quantity) {
                        result += line.quantity;
                    }
                });
                return result;
            },
            allChargesValid() {
                let valid = true;
                this.editJob.jobItems.forEach(line => {
                    if (!line.unitPrice) {
                        return false;
                    }
                    if (!line.product || !line.quantity) {
                        return false;
                    }
                });
                return valid;
            },
        },
        watch: {
            contactSearchTerm: {
                handler: function () {
                    clearTimeout(this.contactSearchTimer);
                    if (this.contactSearchTerm.length > 2) {
                        this.contactSearchTimer = setTimeout(this.searchContacts, 100);
                    } else {
                        this.contactSearchResults = [];
                    }
                }
            },

        }
    };
</script>