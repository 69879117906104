<template>
    <v-main>
        <div class="pa-4">
            <div>
                <v-card class="w-100"
                        elevation="1"
                        rounded="lg">
                    <v-img height="100"
                           style="background:black;"
                           src="/assets/logo.png"></v-img>
                    <v-card-text>
                        <div style="min-height:300px">
                            <v-overlay :model-value="loading"
                                       contained
                                       persistent
                                       class="align-center justify-center">
                                <v-progress-circular color="primary"
                                                     indeterminate
                                                     size="64"></v-progress-circular>
                            </v-overlay>
                            <v-alert icon="mdi-alert-octagram"
                                     title="Sorry"
                                     v-if="errorLoad"
                                     text="We cannot access any information with the provided code. Please contact P-88 on 09 836 4456."
                                     type="warning"></v-alert>
                            <div v-if="data">
                                <h2>{{ data.contact }}</h2>
                                <v-divider class="my-2"></v-divider>
                                <div class="d-flex justify-space-between">
                                    <h3>Active Jobs</h3>
                                    <v-btn v-if="data && data.contactId == 522" @click="downloadReport" :loading="downloading">
                                        Download Active Jobs
                                    </v-btn>
                                </div>
                               
                                <v-sheet class="my-2 pa-2" color="yellow-lighten-4">
                                    <v-icon icon="mdi-alert-decagram-outline"></v-icon> <b>Please note:</b> The current scheduled spray date listed below is intended as a guide only.<br /> While the order will most likely be ready for pickup the following business day, production times are subject to change. <br />For a more accurate timeframe, especially for urgent jobs, please contact us on <a href="tel:098364456">09 836 4456</a> or email <a href="mailto:sales@p88.co.nz">sales@p88.co.nz.</a>
                                </v-sheet>
                                <div>
                                    <v-text-field clearable
                                                  density="compact"
                                                  ref="searchInput"
                                                  hide-details
                                                  v-model="searchTerm"
                                                  label="Search Active jobs..."
                                                  prepend-icon="mdi-magnify"></v-text-field>
                                </div>
                                <v-table density="compact">
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Status
                                            </th>
                                            <th class="text-left">
                                                Created
                                            </th>
                                            <th class="text-left">
                                                Job
                                            </th>
                                            <th v-if="hasContactUser" class="text-left">
                                                Target Customer Pickup
                                            </th>
                                            <th class="text-left">
                                                Current Scheduled Spray Date
                                            </th>
                                            <th class="text-left">
                                                Colour
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="job in filteredData" :key="job.jobNumber">
                                            <td>
                                                <v-chip v-if="job.status >= $constants.jobs.JOBSTAT_INVOICE" variant="flat" color="green-lighten-2" size="x-small">
                                                    <v-icon start icon="mdi-check-circle"></v-icon>
                                                    Completed
                                                </v-chip>
                                                <v-chip v-if="job.status < $constants.jobs.JOBSTAT_INVOICE" variant="flat" color="blue-accent-1" size="x-small">
                                                    <v-icon start icon="mdi-spray"></v-icon>
                                                    In Progress
                                                </v-chip>
                                            </td>
                                            <td>{{ $dayjs(job.dateCreated).format("DD/MM/YYYY") }}</td>
                                            <td>
                                                <span v-html="formatSearchName(job.customerNumber, searchTerm)"></span>
                                                <v-chip v-if="job.pretreated" prepend-icon="mdi-shower-head" variant="flat" color="blue-darken-2" size="x-small" class="ml-1">PRETREATED</v-chip>
                                            </td>
                                            <td v-if="hasContactUser">
                                                <v-text-field type="date"
                                                              density="compact"
                                                              v-model="job.dateCustomerTargetCompletion"
                                                              @change="customerDateChange(job)"></v-text-field>
                                            </td>
                                            <td>
                                                <span v-if="job.scheduledDate">{{ $dayjs(job.scheduledDate).format("DD/MM/YYYY") }}</span>
                                                <span v-if="!job.scheduledDate && job.status != $constants.jobs.JOBSTAT_BLASTING && job.status != $constants.jobs.JOBSTAT_STRIPPING && job.status != $constants.jobs.JOBSTAT_INVOICE">Not scheduled</span>
                                                <span v-if="!job.scheduledDate && job.status == $constants.jobs.JOBSTAT_BLASTING">Queued for Blasting</span>
                                                <span v-if="!job.scheduledDate && job.status == $constants.jobs.JOBSTAT_STRIPPING">Queued for Stripping</span>
                                                <span v-if="!job.scheduledDate && job.status == $constants.jobs.JOBSTAT_INVOICE">Completed</span>
                                            </td>
                                            <td><span v-html="formatSearchName(job.colours, searchTerm)"></span></td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
                <v-card class="w-100 mt-4"
                        v-if="data && data.historics"
                        elevation="1"
                        rounded="lg">
                    <v-card-text>
                        <h3>Completed Jobs</h3>
                        <div>
                            <v-text-field clearable
                                          density="compact"
                                          ref="searchInput"
                                          hide-details
                                          v-model="historicSearchTerm"
                                          label="Search Completed jobs..."
                                          prepend-icon="mdi-magnify"></v-text-field>
                        </div>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Status
                                    </th>
                                    <th class="text-left">
                                        Created
                                    </th>
                                    <th class="text-left">
                                        Job
                                    </th>
                                    <th v-if="hasContactUser" class="text-left">
                                        Target Customer Pickup
                                    </th>
                                    <th class="text-left">
                                        Colour
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="job in historicsPage" :key="job.jobNumber">
                                    <td>
                                        <v-chip v-if="job.status >= $constants.jobs.JOBSTAT_INVOICE" variant="flat" color="green-lighten-2" size="x-small">
                                            <v-icon start icon="mdi-check-circle"></v-icon>
                                            Completed
                                        </v-chip>
                                        <v-chip v-if="job.status < $constants.jobs.JOBSTAT_INVOICE" variant="flat" color="blue-accent-1" size="x-small">
                                            <v-icon start icon="mdi-spray"></v-icon>
                                            In Progress
                                        </v-chip>
                                    </td>
                                    <td>{{ $dayjs(job.dateCreated).format("DD/MM/YYYY") }}</td>
                                    <td>
                                        <span v-html="formatSearchName(job.customerNumber, historicSearchTerm)"></span>
                                    </td>
                                    <td v-if="hasContactUser">
                                        <v-text-field type="date"
                                                      density="compact"
                                                      v-model="job.dateCustomerTargetCompletion"
                                                      @change="customerDateChange(job)"></v-text-field>
                                    </td>
                                    <td><span v-html="formatSearchName(job.colours, historicSearchTerm)"></span></td>
                                </tr>
                            </tbody>
                        </v-table>
                        <v-pagination v-model="page"
                                      :length="totalPages"
                                      class="my-4"></v-pagination>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-main>
</template>
<style>
    
</style>
<script>
    import axios from 'axios'
    import { mapActions, mapState } from "vuex";
    import { formatSearchName } from "../mixins/searchMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            errorLoad: false,
            data: null,
            searchTerm: '',
            historicSearchTerm: '',
            page: 1,
            itemsPerPage: 10,
            downloading: false

        };
    }
    export default {
        name: "CustomerJobs",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadCustomerJobs();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showError", "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            downloadReport() {
                this.downloading = true;
                let contactName = this.data.contact;
                let custCode = this.$route.query.custcode;
                this.$api.request({
                    method: 'GET', url: 'external/customerjobscsv?code=' + custCode, responseType: 'blob'
                    })
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.generateCsvFileName(contactName));
                        document.body.appendChild(link);
                        link.click();
                        this.downloading = false;
                    });
            },
            generateCsvFileName(contact) {
                // Remove spaces and convert the contact name to lowercase
                const cleanedContact = contact.replace(/\s+/g, '').toLowerCase();

                // Get the current date and time
                const now = new Date();

                // Format the date and time components to ensure two-digit representation
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');

                // Create a formatted date-time string, e.g., "20250227_153045"
                const formattedDateTime = `${year}${month}${day}_${hours}${minutes}${seconds}`;

                // Construct the CSV file name using the cleaned contact name and formatted date-time
                return `${cleanedContact}_${formattedDateTime}.csv`;
            },
            formatSearchName,
            loadCustomerJobs() {
                if (this.hasContactUser) {
                    this.$api.get('externalcustomer/customerjobs').then(res => {
                        this.loading = false;
                        this.data = res.data;
                    }).catch(error => {
                        this.loading = false;
                        this.errorLoad = true;
                    });
                } else {
                    let custCode = this.$route.query.custcode;
                    if (process.env.VUE_APP_API_CODE != this.$route.query.code || !custCode || custCode.length <= 0) {
                        this.errorLoad = true;
                    } else {
                        this.loading = true;
                        axios.get(process.env.VUE_APP_API_URL + 'external/customerjobs?code=' + custCode).then(res => {
                            this.loading = false;
                            this.data = res.data;
                        }).catch(error => {
                            this.loading = false;
                            this.errorLoad = true;
                        });
                    }
                }
            },
            customerDateChange(job){
                this.$api.patch('/externalcustomer/'+job.id, [{
                        op: "replace",
                        path: "/dateCustomerTargetCompletion",
                        value: job.dateCustomerTargetCompletion
                    }]).then(res => {
                        this.showSuccessSnack("Requested date updated!");
                        const index = this.data.jobs.findIndex(obj => obj.id === res.id);
                        this.data.jobs.splice(index, 1, res);
                    });
            }
        },
        computed: {
            ...mapState({
                user: (state) => state.auth.user,
            }),
            hasContactUser() {
                return this.user && this.user.contactId;
            },
            filteredData() {
                if (this.data && this.data) {
                    if (this.searchTerm.length > 0) {
                        let upperSearch = this.searchTerm.toUpperCase();
                        return this.data.jobs.filter(item => {
                            return item.customerNumber.toUpperCase().includes(upperSearch) || item.colours.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.data.jobs;
                }
                return [];
            },
            filteredHistorics() {
                if (this.data) {
                    if (this.historicSearchTerm.length > 0) {
                        let upperSearch = this.historicSearchTerm.toUpperCase();
                        return this.data.historics.filter(item => {
                            return item.customerNumber.toUpperCase().includes(upperSearch) || item.colours.toUpperCase().includes(upperSearch);
                        });
                    }
                    return this.data.historics;
                }
                return [];
            },
            historicsPage() {
                const startIndex = (this.page - 1) * this.itemsPerPage;
                const endIndex = startIndex + this.itemsPerPage;
                return this.filteredHistorics.slice(startIndex, endIndex);
            },
            totalPages() {
                return Math.ceil(this.filteredHistorics.length / this.itemsPerPage);
            }
        }
    };
</script>