<template>
    <div class="position-relative">
        <v-overlay :model-value="loading"
                   contained
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <qa-checklist :job="object" :response="response"></qa-checklist>
    </div>
</template>
<style scoped>

</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas, roundAway2dp } from "../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            response: null
        };
    }
    export default {
        name: "JobQaPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loading = true;
            this.loading = true;
            this.$api.get('/jobs/qachecklist?id='+this.object.id)
                .then(res => {
                    if (res.data){
                        this.response = res.data;
                    }
                    this.loading = false;
                }).catch(error => {
                    this.loading = false;
                    this.showErrorSnack("There was an issue getting the QA Checklist.");
                });
        },
        props: {
            object: Object,
            entity: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            numberWithCommas,
        },
        computed: {
        },
        watch: {
        }
    };
</script>