<template>
    <canvas ref="qrCode"></canvas>
</template>
<style scoped>
</style>
<script>
    var QRCode = require('qrcode')
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "JobQrCode",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.$nextTick(() => {
                if (!this.loading && this.$refs.qrCode) {
                    QRCode.toCanvas(this.$refs.qrCode, `${process.env.VUE_APP_URL}${this.$constants.entities.Job.ROUTE}${this.jobId}`, { errorCorrectionLevel: 'H', width: 80 }, function (error) {
                        if (error) console.log(error)
                    })
                }
            });
        },
        props: {
            jobId: Number
        },
        methods: {
        },
        computed: {
        }
    };
</script>