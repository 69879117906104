<template>
    <v-autocomplete v-model="selectedJob"
                    :items="searchResults"
                    @update:search="searchTerm = $event"
                    :loading="searching"
                    label="Search Jobs"
                    item-text="name"
                    item-title="name"
                    return-object
                    hide-details />
</template>

<script>
    import { mapActions } from "vuex";

    function initialState() {
        return {
            searching: false,
            queryTerm: "",
            searchResults: []
        };
    }

    export default {
        name: "JobSearch",
        props: {
            // Use .sync on the parent binding to allow two-way syncing.
            job: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return initialState();
        },
        computed: {
            // Computed getter/setter to sync the selected job with the parent.
            selectedJob: {
                get() {
                    return this.job;
                },
                set(val) {
                    this.$emit("update:job", val);
                }
            },
            searchTerm: {
                get() {
                    return this.queryTerm
                },

                set(searchInput) {
                    if (this.queryTerm !== searchInput) {
                        this.queryTerm = searchInput
                        if (this.queryTerm.length > 3) {
                            this.search()
                        }
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            search() {
                // Use "this.searchTerm" so that the query is based on the current input.
                this.searching = true;
                this.$api.get(`/search/searchjobs?keyword=${this.queryTerm}`)
                    .then(res => {
                        this.searchResults = res.data;
                        this.searching = false;
                    })
                    .catch(error => {
                        this.showErrorSnack("There was an issue searching for the job");
                        this.searching = false;
                    });
            }
        }
    };
</script>

<style scoped>
    /* Add any component-specific styles here */
</style>