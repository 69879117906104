<template>
    <div class="code-block">
        <div v-html="equationDisplay" class="my-1"></div>
    </div>
</template>
<style scoped>
</style>
<script>
    import { getColourFromInt } from '../mixins/colourMixins'
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "EquationDisplay",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            equation:String
        },
        methods: {
        },
        computed: {
            equationDisplay() {
                if (this.equation && this.equation.length > 3) {
                    // Swap vars
                    let display = this.equation;
                    this.$constants.products.equationSwaps.forEach(function (item, i) {
                        let colour = getColourFromInt(i);
                        display = display.replace(item, `<div class='v-chip v-theme--light bg-${colour} v-chip--density-default v-chip--size-x-small v-chip--variant-flat mb-1'><div class="v-chip__content">${item}</div></div>`);
                    });
                    return display;
                }
                return "";
            }
        }
    };
</script>