<template>
    <div class="w-100 mt-2 overflow-hidden">
        <h5 class="box-header"><v-icon icon="mdi-check-underline-circle-outline" class="mr-1"></v-icon> QA CHECKLIST</h5>
        <div class="customer-box qa-box" style="margin-top:0px;">
            <b>STRIPPING / BLASTING</b>
            <ul style="list-style-type: none;" class="mb-1">
                <li v-for="question in stripQuestions" :key="question"><v-icon :icon="responseIcon('strippingChecklist', question)"></v-icon> {{ question }}</li>
                <li v-for="question in blastQuestions" :key="question"><v-icon :icon="responseIcon('strippingChecklist', question)"></v-icon> {{ question }}</li>
                <li>
                    <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                        <div class="enter-section">
                            Name:<span class="line">{{ getResponseField('strippingName') }}</span>
                        </div>
                        <div class="enter-section">
                            Date:<span class="line" style="width:80px">{{ getResponseField('strippingDate') }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="qa-header-sec"><b>PRETREATMENT</b></div>
            <ul style="list-style-type: none;" class="mb-1">
                <li v-for="question in pretreatQuestions" :key="question"><v-icon :icon="responseIcon('pretreatmentChecklist', question)"></v-icon> {{ question }}</li>
                <li>
                    Pretreatment method used:<br />
                    <v-icon :icon="getIcon(getResponseField('pretreatmentMethod') == 'Chromate')"></v-icon> Chromate, <v-icon :icon="getIcon(getResponseField('pretreatmentMethod') == 'Handwash')"></v-icon> Handwash, <v-icon :icon="getIcon(getResponseField('pretreatmentMethod') == 'Prepsol')"></v-icon> Prepsol, <v-icon :icon="getIcon(getResponseField('pretreatmentMethod') == 'Zinc Phosphate')"></v-icon> Zinc Phosphate
                </li>
                <li>
                    <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                        <div class="enter-section">
                            Name:<span class="line">{{ getResponseField('pretreatmentName') }}</span>
                        </div>
                        <div class="enter-section">
                            Date:<span class="line" style="width:80px">{{ getResponseField('pretreatmentDate') }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                        <div class="enter-section">
                            Location:<span class="line">{{ getResponseField('pretreatmentLocation') }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="qa-header-sec"><b>POWDER</b></div>
            <ul style="list-style-type: none;" class="mb-1">
                <li v-for="question in powderQuestions" :key="question"><v-icon :icon="responseIcon('powderChecklist', question)"></v-icon> {{ question }}</li>
                <li>
                    Powder Batch: <span class="line" style="width:246px">{{ getResponseField('powderBatch') }}</span>
                </li>
                <li><span class="line" style="width:322px;margin-top:18px;"></span></li>
                <li>
                    <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                        <div class="enter-section">
                            Name:<span class="line">{{ getResponseField('powderName') }}</span>
                        </div>
                        <div class="enter-section">
                            Date:<span class="line" style="width:80px">{{ getResponseField('powderDate') }}</span>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="qa-header-sec"><b>PACKING</b></div>
            <ul style="list-style-type: none;" class="mb-1">
                <li>
                    Powder thickness range: <span class="line">{{ getResponseField('packingPowderThickness') }}</span>
                </li>
                <li v-for="question in packingQuestions" :key="question"><v-icon :icon="responseIcon('packingChecklist', question)"></v-icon> {{ question }}</li>
                <li>
                    <table class="detail-table mt-0">
                        <tr>
                            <th class="w-80 text-left">TYPE</th>
                            <th class="w-20 text-left">QTY</th>
                        </tr>
                        <tr v-for="type in ['Pallet', 'Bundles', 'Boxes']" :key="type">
                            <td>{{ type }}</td>
                            <td>{{ getResponseField(getTypeField(type)) }}</td>
                        </tr>
                    </table>
                </li>
                <li>
                    <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                        <div class="enter-section">
                            Name:<span class="line">{{ getResponseField('packingName') }}</span>
                        </div>
                        <div class="enter-section">
                            Date:<span class="line" style="width:80px">{{ getResponseField('packingDate') }}</span>
                        </div>
                    </div>
                    <div class="d-flex justify-space-between collection-details mt-2 mb-1">
                        <div class="enter-section">
                            Location:<span class="line">{{ getResponseField('packingLocation') }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="customer-box qa-box">
        <div class="font-weight-bold">
            Collected By
        </div>
        <div class="mt-3">
            Name:<span class="line" style="width:250px">{{ getResponseField('collectedByName') }}</span>
        </div>
        <div class="mt-3">
            Signature:<span class="line" style="width:230px"></span>
        </div>
        <div class="mt-3">
            Date:<span class="line" style="width:255px">{{ getResponseField('collectedByDate') }}</span>
        </div>
    </div>
</template>
<style scoped lang="scss">
    .customer-box {
        width: 100%;
        border: 1px solid black;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 5px;
    }
    .qa-header-sec {
        width: 110%;
        border-top: 1px solid black;
        margin-top: 2px;
        padding-top: 2px;
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 5px;
    }
    .box-header {
        width: 100%;
        padding: 2px;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top: 1px solid black;
        font-family: monospace;
    }
    .qa-box {
        font-size:11px;
    }

    .customer-box .header {
        font-size: 12px;
        display: inline-block;
        width: 100%;
        text-decoration: underline;
    }

    .collection-details {
        margin-top: 15px;
        font-size: 12px;
    }

    .line {
        display: inline-block;
        width: 150px;
        border-bottom: 1px solid black;
        margin-left: 3px;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false,
            stripQuestions : [
                "Product is fully stripped & free of residual chemical",
                "Product quantity and overall quality is correct",
            ],
            blastQuestions: [
                "Product is fully blasted with even finish texture",
                "Product quantity and overall quality is correct",
            ],
            pretreatQuestions: [
                "Product is free of plastic or any other contamination before treatment",
                "Any damage has been notified to office before being run",
            ],
            powderQuestions: [
                "Product is free of contamination and damage",
                "Any damage has been notified to office before being run",
                "Hanging jigs are clean and in good condition",
                "Correct powder selected",
                "Correct equipment settings used for product and powder type",
            ],
            packingQuestions: [
                "Products passed the curing test",
                "Product quantity and overall quality is correct",
                "Product is correctly packaged as per customer expectations",
                "Product location and pack quantity correctly noted"
            ]
        };
    }
    export default {
        name: "QaChecklist",
        data: function () {
            return initialState();
        },
        mounted: function () { 
        },
        props: {
            job: Object,
            response: Object
        },
        methods: {
            getResponseField(field) {
                if (this.response && this.response[field] && this.response[field] != 'null') {
                    return this.response[field];
                }
                return null;
            },
            getTypeField(type) {
                if (type == "Boxes") {
                    return "boxesQty"
                } else if (type == "Bundles") {
                    return "bundlesQty";
                } else if (type == "Pallet") {
                    return "palletQty";
                }
            },
            responseIcon(field, question) {
                let result = false;
                let responseField = this.getResponseField(field);
                if (responseField) {
                    let questionField = responseField.find(quest => {
                        return quest.field.startsWith(question.substring(0, 12));
                    });
                    if (questionField && questionField.ticked == true) {
                        result = true;
                    }
                }
                return this.getIcon(result);
            },
            getIcon(result) {
                return result ? "mdi-check-circle" : "mdi-square-rounded-outline";
            }
        },
        computed: {
        }
    };
</script>