import auth from './auth'
import entities from './entities'
import jobs from './jobs'
import tasks from './tasks'
import rates from './rates'
import warehouse from './warehouse'
import mailTemplates from './mailtemplates'
import products from './products'

export default {
    auth: auth,
    entities: entities,
    jobs: jobs,
    tasks: tasks,
    rates: rates,
    warehouse: warehouse,
    mailTemplates: mailTemplates,
    products: products,
}