import products from "../constants/products";
import rates from "../constants/rates";
export function calculateJobLineUnitPrice(line, currentRates) {
    let price = 0.0;
    if (line.product && line.product.pricingRate) {
        switch (line.product.uomType) {
            case products.UOM_LENG:
                return (line.length / 1000) * line.product.pricingRate;
            case products.UOM_PERI:
                return (line.length / 1000) * line.product.pricingRate;
            case products.UOM_SQM:
                return ((line.width / 1000) * (line.height / 1000)) * line.product.pricingRate;
            case products.UOM_UNIT:
                return line.product.pricingRate;
        }
    }
    return price;
}

export function uomPerDisplay(product) {
    if (product){
        switch (product.uomType) {
            case products.UOM_PERI:
            case products.UOM_LENG:
                return "Per M";
            case products.UOM_SQM:
                return "Per M2";
            case products.UOM_UNIT:
                return "Per Unit";
        }
    }
    return "";
}
export function getComplexityRate(currentRates, complexity) {
    let value = null;
    switch (complexity) {
        case products.COMP_SIM:
            value = 0;
            break;
        case products.COMP_STAND:
            value = getRate(currentRates, rates.RATE_COMSTA, 0);
            break;
        case products.COMP_COMP:
            value = getRate(currentRates, rates.RATE_COMCOM, 0);
            break;
    }
    return value;
}

export function getThicknessRate(currentRates, thickness) {
    let value = null;
    switch (thickness) {
        case products.THICK_4MM:
            value = 0;
            break;
        case products.THICK_4TO6MM:
            value = getRate(currentRates, rates.RATE_THICK4TO6, 0);
            break;
        case products.THICK_7TO9MM:
            value = getRate(currentRates, rates.RATE_THICK7TO9, 0);
            break;
        case products.THICK_10MM:
            value = getRate(currentRates, rates.RATE_THICK10, 0);
            break;
    }
    return value;
}
export function getRate(rates, type, id) {
    let result = rates.find(item => item.type == type && item.classId == id);
    if (result) {
        return result.rate.value;
    }
    return null;
}

export function getComplexity(complexity) {
    let result = products.complexities.find(item => item.id == complexity);
    if (result) {
        return result.label;
    }
    return null;
}