<template>
    <div>
        <v-overlay :model-value="entityRecordLoading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div v-if="entityRecord" class="d-flex">
            <a @click="$router.go(-1)" class="cursor-pointer"><v-icon icon="mdi-chevron-left"></v-icon></a>
            <avatar-small v-if="entityRecord.avatar" :object="entityRecord"></avatar-small>
            <h2 class="panel-header mb-2">{{ entityRecord.name }} 
                <v-chip color="red-darken-3" class="ml-1" variant="flat" v-if="entityRecord.deleted">Deleted</v-chip>
                <entity-tag-chip :object="entityRecord"></entity-tag-chip>
            </h2>
            <v-btn prepend-icon="mdi-pencil-outline"
                   class="ma-3"
                   v-if="panelEntity.UPDATE"
                   @click="editEntity(entityRecord)"
                   size="x-small">EDIT</v-btn>
        </div>
        <v-row v-if="entityRecord && filteredPanels">
            <v-col md="3">
                <v-card v-if="entityRecord" rounded class="detail-panel-height" flat>
                    <v-card-text v-if="panelEntity.DETAILPANEL" class="p-0">
                        <component :is="panelEntity.DETAILPANEL"></component>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="9">
                <v-row>
                    <v-col :lg="panel.size" md="12" v-for="panel in filteredPanels" :key="panel.title">
                        <v-card v-if="panel.inCard" rounded class="pa-1" :class="{'panel-height': !panel.autoHeight }" :title="panel.title" flat>
                            <v-card-text>
                                <component v-if="entityRecord.id == id" :is="panel.component" :object="entityRecord" :entity="panelEntity"></component>
                            </v-card-text>
                        </v-card>
                        <component v-if="!panel.inCard && entityRecord.id == id" :is="panel.component" :object="entityRecord" :entity="panelEntity"></component>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <edit-entity-dialog></edit-entity-dialog>
    </div>
</template>
<style>
    .detail-panel-height{
        min-height:600px;
    }
    .panel-height {
        height: 600px;
        overflow-y: auto;
    }
    .panel-inner-height {
        height: 500px;
    }
    .cursor-pointer{
        cursor:pointer;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    function initialState() {
        return {
            saving: false,
            loading: false,
        };
    }
    export default {
        name: "EntityPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.loadEntity();
        },
        props: {
            id: String,
            panelEntity: Object
        },
        methods: {
            ...mapActions([
                'loadEntityRecord',
                'editEntity'
            ]),
            loadEntity() {
                this.loadEntityRecord({ entityId: this.id, entity: this.panelEntity });
            },
            reset: function () {
                Object.assign(this.$data, initialState());
            },
        },
        computed: {
            ...mapState({
                entityRecord: (state) => state.entities.entityRecord,
                entityRecordLoading: (state) => state.entities.entityRecordLoading
            }),
            filteredPanels() {
                return this.panelEntity.PANELS.filter(panel => {
                    let entityRecordType = this.entityRecord.type ? this.entityRecord.type.toString() : "";
                    return !panel.typeFilter || panel.typeFilter.split(',').includes(entityRecordType);
                });
            }
        },
        watch: {
            id: {
                handler: function () {
                    this.loadEntity();
                }
            }
        }
    };
</script>