<template>
    <div>
        <v-table v-if="items">
            <thead>
                <tr>
                    <th class="text-left">
                        Product
                    </th>
                    <th class="text-right">
                        Qty
                    </th>
                    <th class="text-right">
                        Total
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="line in items" :key="line.id" :class="{'completed-item':(line.quantityCompleted && line.quantity != 0 && line.quantityCompleted >= line.quantity) }">
                    <td class="text-left"><job-item-description :line="line"></job-item-description></td>
                    <td class="text-right">{{ numberWithCommas(line.quantity, 2) }} <v-chip size="x-small" v-if="line.quantityCompleted" variant="flat" color="success"><v-icon start icon="mdi-progress-check"></v-icon> {{ numberWithCommas(line.quantityCompleted, 2) }}</v-chip></td>
                    <td class="text-right">{{ numberWithCommas(lineTotal(line), 2) }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="total-subline">
                    <td class="text-right"><strong>SUBTOTAL</strong></td>
                    <td class="text-right"><strong>{{ numberWithCommas(quantityTotal, 2) }}</strong></td>
                    <td class="text-right"><strong>${{ numberWithCommas(chargeSubTotals, 2) }}</strong></td>
                </tr>
                <tr class="total-subline">
                    <td colspan="2" class="text-right"><strong>TAX</strong></td>
                    <td class="text-right">
                        <strong>${{ numberWithCommas(chargeTaxTotals, 2) }}</strong>
                    </td>
                </tr>
                <tr class="total-line">
                    <td colspan="2" class="text-right"><strong>TOTAL</strong></td>
                    <td class="text-right">
                        <strong>${{ numberWithCommas(chargeTotals, 2) }}</strong>
                    </td>
                </tr>
            </tfoot>
        </v-table>
    </div>
</template>
<style scoped>
    .completed-item td {
        text-decoration: line-through;
    }
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import { numberWithCommas, roundAway2dp } from "../../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            localJob: null,
        };
    }
    export default {
        name: "JobItemsPanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            object: Object,
            entity: Object
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            numberWithCommas,
            lineTotal(line) {
                return (line.totalUnitAmount ?? 0) * line.quantity;
            },
        },
        computed: {
            items() {
                return this.object.jobItems;
            },
            chargeTaxTotals() {
                return roundAway2dp(this.chargeSubTotals * 0.15);
            },
            chargeTotals() {
                return this.chargeSubTotals + this.chargeTaxTotals;
            },
            chargeSubTotals() {
                let result = 0;
                this.items.forEach(line => {
                    if (line.totalUnitAmount && line.quantity) {
                        result += line.totalUnitAmount * line.quantity;
                    }
                });
                return result;
            },
            quantityTotal() {
                let result = 0;
                this.items.forEach(line => {
                    if (line.quantity) {
                        result += line.quantity;
                    }
                });
                return result;
            },
        },
        watch: {
        }
    };
</script>