<template>
    <div class="buttons">
        <div class="button-controls d-flex justify-space-between">
            <v-btn prepend-icon="mdi-printer" @click="printPage">Print</v-btn>
        </div>
    </div>
    <div class="book">
        <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
        <div v-if="!loading && data">
            <div>
                <div class="page">
                    <div class="subpage">
                        <div class="d-flex justify-space-between">
                            <div>
                                <h2><small>WIP Powder Report</small> {{ data.date }}</h2>
                            </div>
                            <div>
                                <v-img height="50"
                                       width="50"
                                       style="background:black;"
                                       src="/assets/logo.png"></v-img>
                            </div>
                        </div>
                        <table class="job-table">
                            <thead class="header-repeat">
                                <tr>
                                    <td colspan="4" class="no-border"></td>
                                </tr>
                            </thead>
                            <tr class="name-row">
                                <th colspan="2" class="text-left">
                                    COLOUR
                                </th>
                                <th class="text-left">RANGE</th>
                                <th class="text-right">POWDER/KG</th>
                            </tr>
                            <tr>
                                <th class="w-10 text-left">#</th>
                                <th class="w-30 text-left">Customer</th>
                                <th class="w-10 text-left">Scheduled</th>
                                <th class="w-10 text-right">KG</th>
                            </tr>
                            <tbody v-for="powder in data.rows" :key="powder.id">
                                <tr class="name-row">
                                    <td colspan="2" class="text-left">{{ powder.data.colourName.toUpperCase() }}</td>
                                    <td class="text-left">{{ powder.data.range }}</td>
                                    <td class="text-right">{{ numberWithCommas(powder.data.totalPowderRequired, 2) }}</td>
                                </tr>
                                <tr v-for="job in powder.data.jobs" :key="job.id">
                                    <td>
                                        {{ job.name }}
                                    </td>
                                    <td><span v-if="job.contactName">{{ job.contactName }}</span></td>
                                    <td class="text-left">
                                        <span v-if="job.scheduledFor">{{ $dayjs(job.scheduledFor).format('DD/MM/YYYY') }}</span>
                                        <span v-if="!job.scheduledFor">Not Scheduled</span>
                                    </td>
                                    <td class="text-right">{{ numberWithCommas(job.totalPowderRequired, 2) }}</td>
                                </tr>
                            </tbody>
                            <tfoot class="footer-repeat">
                                <tr>
                                    <td colspan="4" class="no-border"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapState } from "vuex";
    import { getTagClass } from '../mixins/jobMixins';
    import { numberWithCommas } from "../mixins/numberMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            data: null,
            date: null
        };
    }
    export default {
        name: "WipPowderView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.date = this.$dayjs().format('YYYY-MM-DD');
            this.loadData();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack"
            ]),
            getTagClass,
            numberWithCommas,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            printPage() {
                var css = '@page { size: A4 landscape!important; }',
                    head = document.head || document.getElementsByTagName('head')[0],
                    style = document.createElement('style');

                style.type = 'text/css';
                style.media = 'print';

                if (style.styleSheet){
                  style.styleSheet.cssText = css;
                } else {
                  style.appendChild(document.createTextNode(css));
                }

                head.appendChild(style);
                window.print();
            },
            loadData() {
                this.loading = true;
                this.$api.get('report/wippowder')
                    .then(res => {
                        this.data = res.data;
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.showErrorSnack('There was an issue getting the report');
                    });
            },
        },
        computed: {
        }
    };
</script>
<style scoped lang="scss">
    .buttons {
        width: 100%;
        position: fixed;
        top: 0;
        background-color: #FAFAFA;
        border-bottom: 1px #D3D3D3 solid;
        z-index: 10;
    }

    .button-controls {
        padding-top: 20px;
        width: 287mm;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 20px;
    }

    :deep(.v-textarea textarea) {
        font-size: 12px !important;
    }

    .job-table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 10px;
        border: none !important;
    }

    .job-table, .job-table td, th {
        border: 1px #D3D3D3 solid;
        font-size: 12px;
        padding: 3px;
    }

    body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt "Tahoma";
    }

    .name-row td, th {
        box-shadow: inset -1px -1px 100px 100px #212121;
        font-weight: bold;
        color: white;
    }

    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    .book {
        margin-top: 80px;
    }

    .page {
        width: 287mm;
        min-height: 210mm;
        padding: 10mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    .subpage {
        padding: 1cm;
        width: 257mm;
        height:auto;
    }
    .footer-repeat, .header-repeat {
        display:none;
    }

    @page {
        size: A4;
        margin: 0;
    }

    @media print {
        .subpage {
            page-break-before: auto;
        }

        .no-border {
            border: none !important;
            height:10mm;
        }

        .footer-repeat {
            display: table-footer-group;
            border: none !important;
        }

        .header-repeat {
            display: table-header-group;
            border: none !important;
        }

        .bg-orange-lighten-4 {
            box-shadow: inset -1px -1px 100px 100px #ffe0b2;
        }

        .bg-orange-darken-1 {
            box-shadow: inset -1px -1px 100px 100px #fb8c00;
        }

        .bg-red-lighten-4 {
            box-shadow: inset -1px -1px 100px 100px #FFCDD2;
        }

        .bg-red-darken-1 {
            box-shadow: inset -1px -1px 100px 100px #E53935;
        }

        @page {
            size: landscape A4;
        }

        html, body {
            height: 210mm;
            width: 297mm;
        }

        .book {
            margin-top: 0px;
        }

        .buttons {
            display: none;
        }

        :deep(.v-textarea textarea) {
            overflow: hidden;
        }

        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }
</style>