<template>
    <div>
        <div class="page">
            <div class="subpage">
                <div class="d-flex justify-space-between">
                    <h4 class="ml-2">{{ $dayjs(job.dateTargetCompletion).format('ddd D MMM YYYY') }}</h4>
                    <h4 class="font-italic">PRODUCTION SHEET</h4>
                </div>
                <div class="customer-box mt-0">
                    <v-row>
                        <v-col cols="8">
                            <h3>{{ job.contact.name }}</h3>
                            <h4>{{ job.customerNumber }}</h4>
                        </v-col>
                        <v-col cols="4" class="d-flex">
                            <div class="mr-2 text-right">
                                <h1 style="font-size:26px;">{{ job.name }}</h1>
                            </div>
                            <job-qr-code :job-id="job.id"></job-qr-code>
                        </v-col>
                        
                    </v-row>
                    
                </div>
                <div class="d-flex justify-space-between">
                    <div style="width:48%">
                        <v-sheet v-if="job.warrantyRequired" class="warranty-sheet" rounded color="blue-grey-lighten-5">
                            <h4><v-icon icon="mdi-format-list-checks"></v-icon> Warranty Job</h4>
                            <div class="detail-table mt-0">
                                <div>A) 12 minutes etch & 12 minutes chromatic</div>
                                <div>B) Powder coat 4 samples</div>
                            </div>
                        </v-sheet>
                        <v-sheet v-if="job.packaging" class="warranty-sheet" rounded color="blue-grey-lighten-5">
                            <h4><v-icon icon="mdi-archive-star-outline"></v-icon> Packaging Requirements</h4>
                            <div class="detail-table mt-0">
                                <div>Use packaging type: <h4 class="d-inline">{{ packagingName  }}</h4></div>
                            </div>
                        </v-sheet>
                        <table class="detail-table">
                            <tbody>
                                <tr>
                                    <th class="text-left">Description</th>
                                    <th class="text-right">Quantity</th>
                                </tr>
                                <tr v-for="item in dataItems" :key="item.description">
                                    <td>
                                        <span v-html="item.description.replaceAll('@', '<br />@')"></span>
                                    </td>
                                    <td class="text-right">
                                        {{ item.quantity }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pl-4" style="width:52%">
                        <qa-checklist :job="job"></qa-checklist>
                    </div>
                </div>
            </div>
        </div>
        <div class="page">
            <div class="subpage">
                <div class="w-100 d-flex justify-space-between mb-3">
                    <h1>{{ job.name }}</h1>
                    <div>
                        <v-img height="50"
                               width="50"
                               style="background:black;"
                               src="/assets/logo.png"></v-img>
                    </div>
                </div>
                <div class="w-100 d-flex justify-space-between">
                    <h3>{{ $dayjs(job.created).format('ddd D MMM YYYY') }}</h3>
                    <h3 class="font-italic">PACKING SHEET</h3>
                </div>
                <div class="customer-box">
                    <h2>{{ job.contact.name }}</h2>
                    <h3>{{ job.customerNumber }}</h3>
                </div>
                <table class="detail-table">
                    <tr>
                        <th class="text-left">Description</th>
                        <th class="text-right">Quantity</th>
                    </tr>
                    <tr v-for="item in dataItems" :key="item.description">
                        <td>
                            {{ item.description }}
                        </td>
                        <td class="text-right">
                            {{ item.quantity }}
                        </td>
                    </tr>
                </table>
                <div class="d-flex justify-space-between collection-details">
                    <div>
                        Packed By
                    </div>
                    <div>
                        Name:<span class="line"></span>
                    </div>
                    <div>
                        Signature:<span class="line"></span>
                    </div>
                    <div>
                        Date:<span class="line" style="width:80px"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @import '../../styles/portraitprint.scss';
    .warranty-sheet {
        padding:5px;
        font-size:12px;
        border:1px solid black;
        margin-top:5px;
    }
    .customer-box {
        width: 100%;
        border: 1px solid black;
        margin-top: 5px;
        margin-bottom: 5px;
        padding: 5px;
    }
    .box-header {
        width: 100%;
        padding: 2px;
        border-left: 1px solid black;
        border-right: 1px solid black;
        border-top:1px solid black;
        font-family:monospace;
    }
    .qa-box {
        font-size:11px;
    }

    .customer-box .header {
        font-size: 12px;
        display: inline-block;
        width: 100%;
        text-decoration: underline;
    }

    .collection-details {
        margin-top: 15px;
        font-size: 12px;
    }

    .line {
        display: inline-block;
        width: 150px;
        border-bottom: 1px solid black;
        margin-left: 3px;
    }
</style>
<script>
    function initialState() {
        return {
            loading: false,
        };
    }
    export default {
        name: "JobCard",
        data: function () {
            return initialState();
        },
        mounted: function () { 
        },
        props: {
            job: Object
        },
        methods: {
        },
        computed: {
            dataItems() {
                if (this.job){
                    if (this.job.jobItemTasks && this.job.jobItemTasks.length > 0){
                        return this.job.jobItemTasks.map(item => {
                            return { description: item.itemDescription, quantity: item.quantity };
                        });
                    } else {
                        return this.job.jobItems;
                    }
                }
                return [];
            },
            packagingName() {
                if (this.job && this.job.packaging){
                    return this.$constants.jobs.packaging.find(item => item.value == this.job.packaging).title;
                }
                return null;
            }
        }
    };
</script>