const RATE_PRICING = 0;
const RATE_SQMPRICING = 1;
const RATE_MULTI = 2;
const RATE_LENGTH = 3;
const RATE_DEPTH = 4;
const RATE_HEIGHT = 5;
const RATE_WIDTH = 6;
const RATE_PERI = 7;
const RATE_THICK4 = 8;
const RATE_THICK4TO6 = 9;
const RATE_THICK7TO9 = 10;
const RATE_THICK10 = 11;
const RATE_COMSIM = 12;
const RATE_COMSTA = 13;
const RATE_COMCOM = 14;
const RATE_BOTHSIDES = 15;
const RATE_BLAST = 16;
const RATE_STRIP = 17;

export default {
    RATE_PRICING,
    RATE_SQMPRICING,
    RATE_MULTI,
    RATE_LENGTH,
    RATE_DEPTH,
    RATE_HEIGHT,
    RATE_WIDTH,
    RATE_PERI,
    RATE_THICK4,
    RATE_THICK4TO6,
    RATE_THICK7TO9,
    RATE_THICK10,
    RATE_COMSIM,
    RATE_COMSTA,
    RATE_COMCOM,
    RATE_BOTHSIDES,
    RATE_BLAST,
    RATE_STRIP,
    types: {
        0: {
            id: RATE_PRICING,
            title: 'Pricing',
            hint: 'This is the main pricing figure applied.',
            prefix: '$',
            suffix: ''
        },
        1: {
            id: RATE_SQMPRICING,
            title: 'SQM Pricing',
            hint: 'This is the main SQM pricing figure applied to products.',
            prefix: '$',
            suffix: ''
        },
        2: {
            id: RATE_MULTI,
            title: 'Pricing Multiplier',
            hint: 'This is the multiplier applied to pricing calculation methods. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        3: {
            id: RATE_LENGTH,
            title: 'Length',
            hint: 'This is the products length.',
            prefix: '',
            suffix: 'MM'
        },
        4: {
            id: RATE_DEPTH,
            title: 'Depth',
            hint: 'This is the products depth.',
            prefix: '',
            suffix: 'MM'
        },
        5: {
            id: RATE_HEIGHT,
            title: 'Height',
            hint: 'This is the products height.',
            prefix: '',
            suffix: 'MM'
        },
        6: {
            id: RATE_WIDTH,
            title: 'Width',
            hint: 'This is the products width.',
            prefix: '',
            suffix: 'MM'
        },
        7: {
            id: RATE_PERI,
            title: 'Periphery',
            hint: 'This is the products periphery per lineal meter.',
            prefix: '',
            suffix: 'MM'
        },
        8: {
            id: RATE_THICK4,
            title: 'Material Thickness % Increase - Up to 4mm',
            hint: 'This is the multiplier applied to products up to 4mm. Example value 5 for +5% increase.',
            prefix: '',
            suffix: '%'
        },
        9: {
            id: RATE_THICK4TO6,
            title: 'Material Thickness % Increase - 4mm to 6mm',
            hint: 'This is the multiplier applied to products 4mm to 6mm. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        10: {
            id: RATE_THICK7TO9,
            title: 'Material Thickness % Increase - 7mm to 9mm',
            hint: 'This is the multiplier applied to products 7mm to 9mm. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        11: {
            id: RATE_THICK10,
            title: 'Material Thickness % Increase - 10mm and over',
            hint: 'This is the multiplier applied to products 10mm and over. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        12: {
            id: RATE_COMSIM,
            title: 'Material Complexity % Increase - Simple',
            hint: 'This is the multiplier applied to products with a complexity simple. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        13: {
            id: RATE_COMSTA,
            title: 'Material Complexity % Increase - Standard',
            hint: 'This is the multiplier applied to products with a complexity standard. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        14: {
            id: RATE_COMCOM,
            title: 'Material Complexity % Increase - Complex',
            hint: 'This is the multiplier applied to products with a complexity complex. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        15: {
            id: RATE_BOTHSIDES,
            title: 'Both Sides % Increase',
            hint: 'This is the multiplier applied to products when it is indicated both sides need spraying. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        16: {
            id: RATE_BLAST,
            title: 'Blast % Increase',
            hint: 'This is the multiplier applied to products when it is indicated it needs to be blasted. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
        17: {
            id: RATE_STRIP,
            title: 'Strip % Increase',
            hint: 'This is the multiplier applied to products when it is indicated it needs to be stripped. Example value 10 for +10% increase.',
            prefix: '',
            suffix: '%'
        },
    }
}