<template>
    <div>
        <div class="d-flex mb-2 justify-space-between">
            <div class="v-card-title"><span v-if="!showReady">Warehouse</span></div>
            <v-btn color="primary"
                   size="small"
                   @click="addTransaction"
                   prepend-icon="mdi-plus">
                ADD Transaction
            </v-btn>
        </div>
        <v-row>
            <v-col v-if="showReady" md="4">
                <warehouse-ready-panel></warehouse-ready-panel>
            </v-col>
            <v-col :md="showReady ? 8 : 12">
                <v-data-table-server v-model:items-per-page="itemsPerPage"
                                     v-if="typeof(transactionsTotalItems) != undefined"
                                     density="comfortable"
                                     :headers="tableHeaders"
                                     :items-length="transactionsTotalItems"
                                     :items="transactions"
                                     :loading="loading"
                                     @update:itemsPerPage="itemsPerPageChange"
                                     @update:page="pageChange"
                                     fixed-header>
                    <template v-slot:[`item.transactionDate`]="{ item }">
                        {{ $dayjs(item.transactionDate).format('DD/MM/YYYY') }}
                    </template>
                    <template v-slot:[`item.transactionName`]="{ item }">
                        <a href="#" @click="openTransaction(item)">{{ item.transactionName }}</a>
                        <v-chip v-if="item.damaged" color="warning" variant="flat" size="x-small"><v-icon start icon="mdi-alert-circle-outline"></v-icon> DAMAGED</v-chip>
                    </template>
                    <template v-slot:[`item.type`]="{ item }">
                        <type-chip :type="item.type" :entity="$constants.entities.WarehouseTransaction.TYPE"></type-chip>
                    </template>
                    <template v-slot:[`item.contactName`]="{ item }">
                        <span v-if="!item.contactId">{{ item.contactName }}</span>
                        <router-link v-if="item.contactId" :to="'/'+$constants.entities.Contact.ROUTE+item.contactId">{{ item.contactName }}</router-link>
                    </template>
                    <template v-slot:[`item.jobName`]="{ item }">
                        <span v-if="!item.jobId">{{ item.jobName }}</span>
                        <router-link v-if="item.jobId" :to="'/'+$constants.entities.Job.ROUTE+item.jobId">{{ item.jobName }}</router-link>
                    </template>
                </v-data-table-server>
            </v-col>
        </v-row>
        <v-dialog v-model="showViewTransaction"
                  fullscreen
                  style='z-index:2000'
                  persistent
                  :loading="loadingView"
                  :scrim="false">
            <v-card v-if="viewTransaction">
                <v-toolbar dark
                           color="primary">
                    <v-toolbar-title><v-icon icon="mdi-plus"></v-icon> {{ viewTransaction.transactionName }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon
                           dark
                           @click="showViewTransaction = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <div>
                        <type-chip :type="viewTransaction.type" :entity="$constants.entities.WarehouseTransaction.TYPE"></type-chip> <v-chip v-if="viewTransaction.damaged" color="warning" variant="flat" size="x-small" class="ml-2"><v-icon start icon="mdi-alert-circle-outline"></v-icon> DAMAGED</v-chip>
                    </div>
                    <p>Processed by {{ viewTransaction.createdBy }} on {{ $dayjs(viewTransaction.transactionDate).format('DD/MM/YYYY') }}</p>
                    <p v-if="viewTransaction.contactName">
                        For  <span v-if="!viewTransaction.contactId">{{ viewTransaction.contactName }}</span>
                        <router-link v-if="viewTransaction.contactId" :to="'/'+$constants.entities.Contact.ROUTE+viewTransaction.contactId">{{ viewTransaction.contactName }}</router-link>
                    </p>
                    <p v-if="viewTransaction.jobName">
                        Linked to <span v-if="!viewTransaction.jobId">{{ viewTransaction.jobName }}</span>
                        <router-link v-if="viewTransaction.jobId" :to="'/'+$constants.entities.Job.ROUTE+viewTransaction.jobId">{{ viewTransaction.jobName }}</router-link>
                    </p>
                    <div v-if="!viewTransaction.jobName && viewTransaction.customerNumber">
                        Associated with {{ viewTransaction.customerNumber }}
                        <div class="my-1">
                            <v-btn color="primary" prepend-icon="mdi-link-plus" @click="showLinkJob">Link to job?</v-btn>
                        </div>
                        <v-card v-if="showJobLink" class="py-2 my-2" style="min-height:400px">
                            <v-overlay :model-value="loadingLink"
                                       contained
                                       class="align-center justify-center">
                                <v-progress-circular color="primary"
                                                     indeterminate
                                                     size="64"></v-progress-circular>
                            </v-overlay>
                            <v-table density="compact" class="my-1">
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Date Entered
                                        </th>
                                        <th class="text-left">
                                            Invoice
                                        </th>
                                        <th class="text-left">
                                            Customer#
                                        </th>
                                        <th>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="job in contactJobs" :key="job.id">
                                        <td>{{ job.dateCreated }}</td>
                                        <td>{{ job.name }}</td>
                                        <td>{{ job.customerNumber }}</td>
                                        <td><v-btn prepend-icon="mdi-link-plus" size="small" @click="linkToJob(job)">Link</v-btn></td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-card>
                    </div>
                    <v-row>
                        <v-col v-for="image in viewTransaction.systemFiles" sm="6" md="3" :key="image.id">
                            <a href="#" @click="openImage(image)">
                                <img :src="image.fileLink" alt="image" class="thumbnail-image" />
                            </a>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<style scoped>
    .thumbnail-image {
        padding: .25rem;
        background-color: #fff;
        border: 1px solid #dee2e6;
        border-radius: .25rem;
        max-width: 100%;
        height: auto;
    }
</style>
<script>
    import { numberWithCommas } from "../../mixins/numberMixins";
    import { mapActions, mapState } from "vuex";
    import { api as viewerApi } from "v-viewer"
    function initialState() {
        return {
            saving: false,
            viewTransaction: null,
            showViewTransaction: false,
            showJobLink: false,
            loadingLink: false,
            contactJobs: [],
            loadingView: false
        };
    }
    export default {
        name: "WarehousePanel",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.initTransactions({job:this.job, contact:this.contact});
        },
        props: {
            object: Object,
            entity: Object,
            showReady: Boolean
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
                "initTransactions",
                "loadTransactions",
                "showAddTransaction"
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            addTransaction(){
                this.showAddTransaction({job: this.job, contact: this.contact});
            },
            openTransaction(transaction) {
                this.viewTransaction = transaction;
                this.showViewTransaction = true;
            },
            showLinkJob() {
                this.showJobLink = true;
                this.loadingLink = true;
                this.$api.get(`${this.$constants.entities.Job.ROUTE}entityjobs?contactId=${this.viewTransaction.contactId}&unreceivedOnly=true`)
                    .then(res => {
                        this.contactJobs = res.data.items;
                        this.loadingLink = false;
                    }).catch(error => {
                        this.showErrorSnack("There was an issue loading this contacts jobs");
                        this.loadingLink = false;
                    });
            },
            linkToJob(job){
                this.$confirm(
                {
                    message: `Are you sure you want to link this transaction to ${job.name}?`,
                    disableKeys: false,
                    auth: false,
                    button: {
                        no: 'No',
                        yes: 'Yes'
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.loadingView = true;
                            this.$api.post(`${this.$constants.entities.WarehouseTransaction.ROUTE}linktransaction?id=${this.viewTransaction.id}&jobid=${job.id}`)
                                .then(res => {
                                    this.loadingView = false;
                                    this.viewTransaction = res.data;
                                    // update in list
                                }).catch(error => {
                                    this.loadingView = false;
                                    this.showErrorSnack("There was an issue loading this contacts jobs");
                                });
                        }
                    }
                }
                )
            },
            openImage(image){
                 var imageIndex = this.getImages.findIndex((image) => {
                     return image.id == image.id;
                 });
                 if (imageIndex > -1){
                     const $viewer = viewerApi({
                         options: {
                             toolbar: false,
                             url: 'src',
                             initialViewIndex: imageIndex
                         },
                         images: this.getImages
                     });
                 } else {
                     window.location = image.fileLink;
                 }
                 return false;
            },
            itemsPerPageChange(itemsPerPage) {
                this.itemsPerPage = itemsPerPage;
                this.loadTransactions();
            },
            pageChange(page) {
                this.page = page;
                this.loadTransactions();
            },
            numberWithCommas,
        },
        computed: {
            ...mapState({
                transactions: (state) => state.warehouse.transactions,
                transactionsTotalItems: (state) => state.warehouse.transactionsTotalItems,
                loading: (state) => state.warehouse.loading,
            }),
            itemsPerPage: {
                get() {
                    return this.$store.state.warehouse.transactionItemsPerPage
                },
                set(value) {
                    this.$store.commit('setWarehouseTransactionItemsPerPage', value)
                }
            },
            page: {
                get() {
                    return this.$store.state.warehouse.transactionPage
                },
                set(value) {
                    this.$store.commit('setWarehouseTransactionPage', value)
                }
            },
            job(){
                if (typeof this.entity !== 'undefined' && this.entity.TYPE == "Job") {
                    return this.object;
                }
                return null;
            },
            contact(){
                if (typeof this.entity !== 'undefined' && this.entity.TYPE == "Contact") {
                    return this.object;
                } else if (typeof this.entity !== 'undefined' && this.entity.TYPE == "Job" && this.object.contact != null){
                    return this.object.contact;
                }
                return null;
            },
            tableHeaders() {
                if (this.job) {
                    return [
                        { title: 'Date', key: 'transactionDate', width: "10%" },
                        { title: 'Type', key: 'type', width: "10%" },
                        { title: 'Description', key: 'transactionName', width: "40%" },
                        { title: 'By', key: 'createdByShort', width: "10%" },
                    ];
                } else if (this.contact) {
                    return [
                        { title: 'Date', key: 'transactionDate', width: "10%" },
                        { title: 'Type', key: 'type', width: "10%" },
                        { title: 'Description', key: 'transactionName', width: "40%" },
                        { title: 'Job', key: 'jobName', width: "10%" },
                        { title: 'By', key: 'createdByShort', width: "10%" },
                    ];
                } else {
                    return [
                        { title: 'Date', key: 'transactionDate', width: "10%" },
                        { title: 'Type', key: 'type', width: "10%" },
                        { title: 'Description', key: 'transactionName', width: "40%" },
                        { title: 'Contact', key: 'contactName', width: "20%" },
                        { title: 'Job', key: 'jobName', width: "10%" },
                        { title: 'By', key: 'createdByShort', width: "10%" },
                    ];
                }
            },
            getImages() {
                if (this.viewTransaction){
                    return this.viewTransaction.systemFiles.filter(function (item) {
                        var extension = item.saveName.split('.').pop().toLowerCase();
                        return ['png', 'jpeg', 'jpg', 'gif', 'tiff', 'tif', 'bmp'].includes(extension);
                    }).map(function (item) {
                        return { src: item.fileLink, id: item.id };
                    });
                }
                return [];
            }
        },
        watch: {
        }
    };
</script>