<template>
    <v-form v-model="valid" @submit.prevent="submit" :disabled="loading">
        <v-row>
            <v-col cols="10">
                <div>
                    <v-card title="Type" class="mb-2">
                        <v-card-subtitle>
                            Select what type of product this will be.
                        </v-card-subtitle>
                        <v-card-text>
                            <v-list v-model="editProduct.type" :lines="false">
                                <v-list-item v-for="(item, i) in $constants.products.types"
                                             :key="i"
                                             :value="item"
                                             :active="item.id === editProduct.type"
                                             @click="editProduct.type = item.id"
                                             color="primary">
                                    <template v-slot:prepend>
                                        <v-icon :icon="item.icon"></v-icon>
                                    </template>
                                    <template v-slot:title>
                                        <div v-html="item.title"></div>
                                    </template>
                                    <template v-slot:subtitle>
                                        <div v-html="item.subtitle"></div>
                                    </template>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="maxShowStep > 1" title="Details" class="mb-2">
                        <v-card-subtitle>
                            Complete the details for the product.
                        </v-card-subtitle>
                        <v-card-text>
                            <v-text-field v-model="editProduct.name"
                                          v-on:change="addCode"
                                          :rules="[$rules.required]"
                                          label="Name *"></v-text-field>
                            <v-text-field v-model="editProduct.code"
                                          :rules="[$rules.required]"
                                          label="Code *"></v-text-field>
                            <div v-if="editProduct.type == $constants.products.TYPE_CUST">
                                <p v-if="!editProduct.contact">Please select the {{ $constants.entities.Contact.SINGULAR }} this is for.</p>
                                <p v-if="editProduct.contact">Current {{ $constants.entities.Contact.SINGULAR }} selected: <contact-link :contact="editProduct.contact"></contact-link></p>
                                <v-text-field :label="'Search for the '+$constants.entities.Contact.SINGULAR+'...'" v-model="contactSearchTerm" :loading="contactSearching"></v-text-field>
                                <v-list lines="one">
                                    <v-list-item v-for="option in contactSearchResults"
                                                 :key="option.id"
                                                 :value="option"
                                                 :active="activeContactSearchResult(option)"
                                                 @click="editProduct.contact = option"
                                                 color="primary">
                                        <template v-slot:prepend>
                                            <v-icon :icon="activeContactSearchResult(option) ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"></v-icon>
                                        </template>
                                        <template v-slot:title>
                                            <span v-html="formatSearchName(option.name, contactSearchTerm)"></span>
                                        </template>
                                        <div><i class="font-weight-bold">Email: </i><span v-html="formatSearchName(option.accountsEmail, contactSearchTerm)"></span></div>
                                        <div><i class="font-weight-bold">Phone: </i><span v-html="formatSearchName(option.phone, contactSearchTerm)"></span></div>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="maxShowStep > 2" title="UOM's">
                        <v-card-subtitle>
                            Enter the structure and uom information below for the product. This will be used in calculating pricing.
                        </v-card-subtitle>
                        <v-card-text>
                            <p>Select the main UOM of this product from the list below.</p>
                            <v-list v-model="editProduct.uomType" :lines="false">
                                <v-list-item v-for="(item, i) in $constants.products.uoms"
                                             :key="i"
                                             :value="item"
                                             :active="item.id === editProduct.uomType"
                                             @click="editProduct.uomType = item.id"
                                             color="primary">
                                    <template v-slot:prepend>
                                        <v-icon :icon="item.icon"></v-icon>
                                    </template>
                                    <template v-slot:title>
                                        <div v-html="item.title"></div>
                                    </template>
                                    <template v-slot:subtitle>
                                        <div v-html="item.subtitle"></div>
                                    </template>
                                </v-list-item>
                            </v-list>
                            <div v-if="editProduct.type != 1 && editProduct.uomType != null">
                                <p>Please enter the required product's UOM's below.</p>
                                <div v-if="editProduct.uomType == $constants.products.UOM_PERI">
                                    <v-text-field label="Periphery *"
                                                  v-model.number="editProduct.periphery"
                                                  hint="Periphery of product in millimeters"
                                                  persistent-hint
                                                  type="number"
                                                  suffix="MM"></v-text-field>
                                    <div class="d-flex pa-4">
                                        <v-checkbox-btn v-model="editProduct.fixedSize"
                                                        label="Fixed length?"
                                                        class="pe-2"></v-checkbox-btn>
                                        <v-text-field label="Length"
                                                      v-model.number="editProduct.length"
                                                      hint="Length of product in millimeters"
                                                      persistent-hint
                                                      :disabled="!editProduct.fixedSize"
                                                      hide-details
                                                      type="number"
                                                      suffix="MM"></v-text-field>
                                    </div>
                                </div>
                                <div v-if="editProduct.uomType == $constants.products.UOM_LENG">
                                    <div class="d-flex pa-4">
                                        <v-checkbox-btn v-model="editProduct.fixedSize"
                                                        label="Fixed length?"
                                                        class="pe-2"></v-checkbox-btn>
                                        <v-text-field label="Length"
                                                      v-model.number="editProduct.length"
                                                      hint="Length of product in millimeters"
                                                      persistent-hint
                                                      :disabled="!editProduct.fixedSize"
                                                      hide-details
                                                      type="number"
                                                      suffix="MM"></v-text-field>
                                    </div>
                                </div>
                                <div v-if="editProduct.uomType == $constants.products.UOM_SQM">
                                    <div class="d-flex pa-4">
                                        <v-checkbox-btn v-model="editProduct.fixedSize"
                                                        label="Fixed size?"
                                                        class="pe-2"></v-checkbox-btn>
                                        <v-text-field label="Width"
                                                      v-model.number="editProduct.width"
                                                      hint="Width of product in millimeters"
                                                      persistent-hint
                                                      :disabled="!editProduct.fixedSize"
                                                      hide-details
                                                      type="number"
                                                      suffix="MM"></v-text-field>
                                        <v-text-field label="Height"
                                                      v-model.number="editProduct.height"
                                                      hint="Height of product in millimeters"
                                                      persistent-hint
                                                      :disabled="!editProduct.fixedSize"
                                                      hide-details
                                                      class="ml-2"
                                                      type="number"
                                                      suffix="MM"></v-text-field>
                                    </div>
                                </div>
                                <div v-if="editProduct.uomType == 'Unit Only'">
                                    <p>No UOM's required.</p>
                                </div>
                            </div>
                            <div v-if="editProduct.uomType">
                                <rates-display v-if="editMode" :type="$constants.rates.RATE_PRICING" :class-id="editProduct.id" class-name="Product"></rates-display>
                                <v-text-field label="Pricing Rate"
                                              v-if="!editMode"
                                              v-model.number="editProduct.pricingRate"
                                              persistent-hint
                                              hide-details
                                              class="ml-2"
                                              type="number"
                                              prefix="$"></v-text-field>
                                <div>{{ pricingRateHelp }}</div>
                            </div>
                            
                            <v-alert density="compact"
                                     v-if="uomErrors.length > 0"
                                     type="warning"
                                     class="my-2"
                                     title="Missing UOM Information">
                                <ul>
                                    <li v-for="error in uomErrors" :key="error">{{ error }}</li>
                                </ul>
                            </v-alert>
                        </v-card-text>
                    </v-card>
                </div>
            </v-col>
            <v-col cols="2">
                <v-card title="Product Details">
                    <v-card-subtitle>
                        Review the details of the product below before saving.
                    </v-card-subtitle>
                    <v-card-text>
                        <v-list lines="one">
                            <v-list-item v-if="editProduct.type">
                                <type-chip :type="editProduct.type" entity="Product"></type-chip>
                            </v-list-item>
                            <v-list-item v-if="editProduct.name" title="Name" :subtitle="editProduct.name">
                            </v-list-item>
                            <v-list-item v-if="editProduct.code" title="Code" :subtitle="editProduct.code">
                            </v-list-item>
                            <v-list-item v-if="editProduct.contact" title="Contact">
                                <contact-link :contact="editProduct.contact"></contact-link>
                            </v-list-item>
                            <v-list-item v-if="editProduct.uomType" title="UOM Type" :subtitle="editProduct.uomType">
                            </v-list-item>
                            <v-list-item title="Fixed size?" :subtitle="editProduct.fixedSize ? 'Yes' : 'No'">
                            </v-list-item>
                            <v-list-item v-if="editProduct.periphery" title="Periphery" :subtitle="editProduct.periphery+' MM'">
                            </v-list-item>
                            <v-list-item v-if="editProduct.length" title="Length" :subtitle="editProduct.length+' MM'">
                            </v-list-item>
                            <v-list-item v-if="editProduct.width" title="Width" :subtitle="editProduct.width+' MM'">
                            </v-list-item>
                            <v-list-item v-if="editProduct.height" title="Height" :subtitle="editProduct.height+' MM'">
                            </v-list-item>
                            <v-list-item v-if="editProduct.pricingRate && !editMode" title="Pricing Rate" :subtitle="'$'+editProduct.pricingRate">
                            </v-list-item>
                        </v-list>
                        <v-alert v-if="saveValid"
                                 type="success"
                                 title="Ready to Save"
                                 text="Product is ready to be saved. Click the save button below."
                                 class="my-2">
                        </v-alert>
                        <div class="mt-2 pt-2">
                            <v-btn :loading="loading"
                                   type="submit"
                                   :disabled="!saveValid"
                                   flat
                                   color="primary"
                                   prepend-icon="mdi-floppy">SAVE</v-btn>
                            <v-btn flat class="ml-2" @click="showEntityForm = false">CANCEL</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-alert density="compact"
                 v-if="errors"
                 type="warning"
                 class="my-2"
                 :title="errors.title">
            <ul>
                <li v-for="error in errors.errors" :key="error" v-html="error[0]"></li>
            </ul>
        </v-alert>
    </v-form>
</template>
<style scoped>
</style>
<script>
    import { mapActions, mapState } from "vuex";
    import _cloneDeep from 'lodash/cloneDeep';
    import { encodeProductBasic } from '../mixins/entityEncodeMixins';
    import { numberWithCommas } from "../mixins/numberMixins";
    import { formatSearchName } from "../mixins/searchMixins";
    function initialState() {
        return {
            saving: false,
            loading: false,
            valid: false,
            errors: null,
            contactSearchTerm: "",
            contactSearching: false,
            contactSearchResults: [],
            searchTimer: null,
            editProduct: {
                id: null,
                type: null,
                name: "",
                code: "",
                contact: null,
                fixedSize: false,
                //defaultAllOver: false,
                uomType: null,
                //complexity: 2,
                length: null,
                periphery: null,
                width: null,
                height: null,
                //defaultThickness: "Up to 4mm"
            },
        };
    }
    export default {
        name: "ProductFrom",
        data: function () {
            return initialState();
        },
        mounted: function () {
            if (this.editEntity) {
                this.editProduct = _cloneDeep(this.editEntity);
            }
        },
        props: {
        },
        methods: {
            numberWithCommas,
            ...mapActions([
                "showSuccessSnack",
                "showErrorSnack",
                "reloadEntities"
            ]),
            formatSearchName,
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            addCode() {
                if (this.editProduct.name.length > 0 && this.editProduct.code.length == 0){
                    this.editProduct.code = this.editProduct.name.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s+/g, '-').toUpperCase();
                }
            },
            submit() {
                this.loading = true
                let formData = new FormData();
                let config = {
                    method: 'post',
                    url: this.entity.CREATE,
                    data: new FormData()
                }
                if (this.editMode) {
                    config = {
                        method: 'put',
                        url: this.entity.UPDATE + this.editEntity.id,
                        data: new FormData()
                    }
                }
                formData = encodeProductBasic(formData, this.editProduct);
                let i = 0;
                config.data = formData;
                this.$api(config)
                    .then(res => {
                        this.loading = false;
                        this.showEntityForm = false;
                        this.reset();
                        this.showSuccessSnack("Updated!");
                        this.reloadEntities();
                    }).catch(error => {
                        if (error.response.data && Array.isArray(error.response.data)) {
                            this.errors = error.response.data;
                        } else if (error.response.data.title) {
                            this.errors = { title: "Whoops", errors: [[error.response.data.title]] };
                        } else {
                            this.errors = { title: "Whoops", errors: [["There was an issue saving the colour."]] };
                        }
                        this.loading = false;
                    });
            },
            activeContactSearchResult(contact){
                return this.editProduct.contact && this.editProduct.contact.id == contact.id;
            },
            searchContacts() {
                this.contactSearching = true;
                let route = this.$constants.entities.Contact.ROUTE + "?searchTerm=" + this.contactSearchTerm;
                this.$api.get(route)
                    .then(res => {
                        this.contactSearching = false;
                        this.contactSearchResults = res.data.items;
                    }).catch(error => {
                        this.showErrorSnack('There was an error searching the table');
                        this.contactSearching = false;
                    });
            },
        },
        computed: {
            ...mapState({
                entity: (state) => state.entities.entity,
                editEntity: (state) => state.entities.editEntity,
            }),
            showEntityForm: {
                get() {
                    return this.$store.state.entities.showEntityForm
                },
                set(value) {
                    this.$store.commit('setShowEntityForm', value)
                }
            },
            editMode() {
                return this.editEntity != null;
            },
            noUomType() {
                return this.editProduct.uomType == null;
            },
            area() {
                if (this.width && this.height){
                    return (this.width/1000) * (this.height/1000);
                }
                return null;
            },
            saveValid() {
                return this.maxShowStep == 3 && this.uomErrors.length == 0;
            },
            pricingRateHelp() {
                switch(this.editProduct.uomType){
                    case this.$constants.products.UOM_PERI:
                    case this.$constants.products.UOM_LENG:
                        return "This is the lineal meter rate.";
                    case this.$constants.products.UOM_SQM:
                        return "This is the SQM rate";
                    case this.$constants.products.UOM_UNIT:
                        return "This is the per unit rate";
                    default:
                        return "";
                }
            },
            uomErrors() {
                let errors = [];
                if (!this.editProduct.uomType) {
                    errors.push('Need a uom type');
                }
                if (this.editProduct.type != this.$constants.products.TYPE_DEF){
                    if (this.editProduct.uomType == this.$constants.products.UOM_PERI){
                        if (!this.editProduct.periphery){
                            errors.push('Periphery figure is required for this UOM.');
                        }
                        if (this.editProduct.fixedSize && !this.editProduct.length) {
                            errors.push('Length is required if fixed length is ticked.');
                        }
                    } else if (this.editProduct.uomType == this.$constants.products.UOM_LENG){
                        if (this.editProduct.fixedSize && !this.editProduct.length) {
                            errors.push('Length is required if fixed length is ticked.');
                        }
                    } else {
                        if (this.editProduct.fixedSize && !this.editProduct.width) {
                            errors.push('Width is required if fixed size is ticked.');
                        }
                        if (this.editProduct.fixedSize && !this.editProduct.height) {
                            errors.push('Height is required if fixed size is ticked.');
                        }
                        if (this.editProduct.fixedSize && !this.editProduct.depth) {
                            errors.push('Depth is required if fixed size is ticked.');
                        }
                    }
                }
                if (this.editProduct.type == this.$constants.products.TYPE_CUST && !this.editProduct.contact) {
                     errors.push('A customer is required.');
                }
                return errors;
            },
            maxShowStep() {
                if (this.editProduct.type == null){
                    return 1;
                } else if (this.editProduct.name.length == 0 && this.editProduct.code.length == 0){
                    return 2;
                } else {
                    return 3;
                }
            },
            complexityLabel() {
                if (this.editProduct.complexity){
                    let complexity = this.$constants.products.complexities.find(item => item.id == this.editProduct.complexity);
                    if (complexity){
                        return complexity.label;
                    }
                }
                return null;
            }
        },
        watch: {
            contactSearchTerm: {
                handler: function () {
                    clearTimeout(this.searchTimer);
                    if (this.contactSearchTerm.length > 2) {
                        this.searchTimer = setTimeout(this.searchContacts, 100);
                    } else {
                        this.contactSearchResults = [];
                    }
                }
            },
        }
    };
</script>