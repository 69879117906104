import { shallowRef, ref, computed } from 'vue'
import JobForm from '../../forms/JobForm.vue'
import JobDetailsPanel from '../../components/panels/JobDetailsPanel.vue'
import FilesPanel from '../../components/panels/FilesPanel.vue'
//import JobStatusPanel from '../../components/panels/JobStatusPanel.vue'
import MailPanel from '../../components/panels/MailPanel.vue';
import JobItemsPanel from '../../components/panels/JobItemsPanel.vue';
import WarehousePanel from '../../components/warehouse/WarehousePanel.vue';
import JobQaPanel from '../../components/panels/JobQaPanel.vue';

export default {
    ICON: 'mdi-clipboard-list-outline',
    TYPE: 'Job',
    SINGULAR: 'Job',
    PLURAL: 'Jobs',
    ROUTE: 'jobs/',
    DELETE: null,
    CREATE: 'jobs/create/',
    UPDATE: null,
    FORM: shallowRef(JobForm),
    DETAILPANEL: JobDetailsPanel,
    HASDETAIL: true,
    TABLEHEADERS: [
        { title: 'Status', key: 'type', width: "10%" },
        { title: 'Name', key: 'name', width: "30%" },
        { title: 'Customer Number', key: 'customerNumber', width: "15%" },
        { title: 'For', key: 'contact.name', width: "30%" },
        { title: 'Total', key: 'jobTotal', width: "15%" },
    ],
    PANELS: [
        { title: 'Items', component: JobItemsPanel, size: 6, inCard: true },
        { title: 'Files', component: FilesPanel, size: 6, inCard: true },
        //{ title: '', component: JobStatusPanel, size: 12, inCard: false, autoHeight: true },
        { title: '', component: WarehousePanel, size: 6, inCard: true },
        { title: 'QA Checklist', component: JobQaPanel, size: 6, inCard: true },
        { title: 'Mail', component: MailPanel, size: 12, inCard: true },
    ]
}