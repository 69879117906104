import { shallowRef, ref, computed } from 'vue'
import ProductForm from '../../forms/ProductForm.vue'
import ProductDetailsPanel from '../../components/panels/ProductDetailsPanel.vue'
import JobTablePanel from '../../components/panels/JobTablePanel.vue'

export default {
    ICON: 'mdi-shape',
    TYPE: 'Product',
    SINGULAR: 'Product',
    PLURAL: 'Products',
    ROUTE: 'products/',
    DELETE: 'products/',
    CREATE: 'products/',
    UPDATE: 'products/',
    FORM: shallowRef(ProductForm),
    HASDETAIL: true,
    DETAILPANEL: ProductDetailsPanel,
    TABLEHEADERS: [
        { title: 'Type', key: 'type' },
        { title: 'Name', key: 'name' },
        { title: 'Code', key: 'code' },
        { title: 'UOM', key: 'uomType' },
        { title: 'Contact', key: 'contact' },
        { title: 'Rate', key: 'pricingRate' },
        { title: '', key: 'controls' }
    ],
    PANELS: [
        { title: 'Jobs', component: JobTablePanel, size: 12, inCard: true },
    ]
}