const UOM_SQM = 'Square Meters';
const UOM_PERI = 'Periphery Length';
const UOM_LENG = 'Length Only';
const UOM_UNIT = 'Unit Only';
const TYPE_DEF = 1;
const TYPE_GEN = 2;
const TYPE_CUST = 3;
const THICK_4MM = "<4mm";
const THICK_4TO6MM = "4-6mm";
const THICK_7TO9MM = "7-9mm";
const THICK_10MM = "10mm<";
const COMP_SIM = 1; 
const COMP_STAND = 2;
const COMP_COMP = 3;
export default {
    UOM_SQM,
    UOM_PERI,
    UOM_LENG,
    UOM_UNIT,
    TYPE_DEF,
    TYPE_GEN,
    TYPE_CUST,
    THICK_4MM,
    THICK_4TO6MM,
    THICK_7TO9MM,
    THICK_10MM,
    COMP_SIM,
    COMP_STAND,
    COMP_COMP,
    complexities: [
        { id: COMP_SIM, label: 'Simple' },
        { id: COMP_STAND, label: 'Standard' },
        { id: COMP_COMP, label: 'Complex' },
    ],
    uoms: [
        {
            id: UOM_SQM,
            icon: 'mdi-circle-multiple-outline',
            title: UOM_SQM,
            subtitle: `Total SqM is to be entered for the product everytime (Height x Length in millimeters). Good for gates, fences or square panels and objects.`,
            equation: '$Width * $Height * $PricingRate * (1 + $Thickness% + $Complexity% + $CoatAllOver% + $ColourGroup%)',
            customEquation: '$Width * $Height * $CustomPricingRate * (1 + $ColourGroup%)',
        },
        {
            id: UOM_PERI,
            icon: 'mdi-ruler-square',
            title: UOM_PERI,
            subtitle: `This is used for variable length fixed periphery products. Great for variable length extruded products.`,
            equation: '$Periphery * $LinealMeters * $PricingRate * (1 + $Thickness% + $Complexity% + $CoatAllOver% + $ColourGroup%)',
            customEquation: '$LinealMeters * $CustomPricingRate * (1 + $ColourGroup%)',
        },
        {
            id: UOM_LENG,
            icon: 'mdi-ruler',
            title: UOM_LENG,
            subtitle: `This should be used for any products when you do not know the periphery. Great for variable length extruded products.`,
            equation: '0.15 * $LinealMeters * $PricingRate * (1 + $Thickness% + $Complexity% + $CoatAllOver% + $ColourGroup%)',
            customEquation: '$LinealMeters * $CustomPricingRate * (1 + $ColourGroup%)',
        },
        {
            id: UOM_UNIT,
            icon: 'mdi-size-xs',
            title: UOM_UNIT,
            subtitle: `This should be used for small widgets (palm sized).`,
            equation: '$PricingRate * ($Thickness% + $Complexity% + $CoatAllOver% + $ColourGroup%)',
            customEquation: '$CustomPricingRate * (1 + $ColourGroup%)',
        }
    ],
    equationSwaps: [
        '$LinealMeters',
        '$PricingRate',
        '$Periphery',
        '$Width',
        '$Height',
        '$CustomPricingRate',
        '$Thickness%',
        '$Complexity%',
        '$CoatAllOver%',
        '$ColourGroup%'
    ],
    types: [
        {
            id: TYPE_GEN,
            icon: 'mdi-cube-scan',
            title: 'Generic',
            subtitle: `Used to define a reoccuring product. Product can be used by any customer. Examples - 50x50 right angle, chain link fence, 50mm pole.`,
        },
        {
            id: TYPE_CUST,
            icon: 'mdi-cube-send',
            title: 'Customer',
            subtitle: `Used to define a customer specific product. Must be assigned to the customer to be used. Examples - Nu-Wall E200 Board.`,
        }
    ],
    thicknessOptions: [
        THICK_4MM,
        THICK_4TO6MM,
        THICK_7TO9MM,
        THICK_10MM
    ]
}