<template>
    <div>
        <v-overlay :model-value="loading"
                   class="align-center justify-center">
            <v-progress-circular color="primary"
                                 indeterminate
                                 size="64"></v-progress-circular>
        </v-overlay>
        <div class="d-flex">
            <div class="flex-1-0">
                <h1><v-icon icon="mdi-file-document-arrow-right"></v-icon> Document Imports</h1>
            </div>
        </div>
        <v-card rounded class="pa-1" flat>
            <v-card-text>
                <div class="d-flex mb-2">
                    <v-btn-toggle v-model="filter" divided color="primary">
                        <v-btn value="All">
                            <span class="hidden-sm-and-down">All</span>
                            <v-icon end icon="mdi-filter-remove-outline">
                            </v-icon>
                        </v-btn>
                        <v-btn value="Unmatched">
                            <span class="hidden-sm-and-down">Unmatched</span>
                            <v-icon end icon="mdi-close-circle"></v-icon>
                        </v-btn>
                    </v-btn-toggle>
                </div>
                <v-table density="compact" class="border">
                    <thead>
                        <tr>
                            <th class="w-10">Imported</th>
                            <th class="w-20"></th>
                            <th class="w-20">Matched To</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="rec in filteredList" :key="rec.file.id">
                            <td>{{ rec.file.dateCreated }}</td>
                            <td>
                                <a href="javascript:;" @click="openFile(rec.file)">
                                    <img :src="rec.file.fileLink" style="width:200px;" />
                                </a>
                            </td>
                            <td :class="{'text-red': rec.job == null, 'text-green': rec.job != null && rec.file.jobId != null, 'text-warning' : rec.job != null && rec.file.jobId == null}">
                                <div v-if="rec.job && rec.file.jobId != null"><v-icon icon="mdi-check-circle"></v-icon> Matched to <job-link :job="rec.job"></job-link></div>
                                <div v-if="!rec.job">
                                    <v-icon icon="mdi-close-circle"></v-icon> Not Matched
                                    <v-btn prepend-icon="mdi-trash-can-outline"
                                       @click="showConfirmDelete(rec.file)"
                                       size="x-small">DELETE</v-btn>
                                    <job-search v-model:job="rec.job" @update:job="assignJob(rec)"></job-search>
                                    
                                </div>
                                <div v-if="rec.job && rec.file.jobId == null">
                                    <v-icon icon="mdi-format-horizontal-align-center"></v-icon> Matching...
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card-text>
        </v-card>
    </div>
</template>
<style scoped>
</style>
<script>
    import { mapActions } from "vuex";
    import { api as viewerApi } from "v-viewer"
    function initialState() {
        return {
            saving: false,
            loading: false,
            data: [],
            filter: 'Unmatched',
        };
    }
    export default {
        name: "DocumentImportsView",
        data: function () {
            return initialState();
        },
        mounted: function () {
            this.getDocuments();
        },
        props: {
        },
        methods: {
            ...mapActions([
                "showErrorSnack",
                "showSuccessSnack",
            ]),
            reset: function () {
                Object.assign(this.$data, initialState());
            },
            getDocuments() {
                this.loading = true;
                this.$api.get("/SystemFiles/documentimports/").then(res => {
                    this.loading = false;
                    this.data = res.data;
                }).catch(error => {
                    this.loading = false;
                    this.showErrorSnack("There was an issue getting the doucments.");
                });
            },
            openFile(file){
                var imageIndex = this.getImages.findIndex((image) => {
                    return image.id == file.id;
                });
                if (imageIndex > -1){
                    const $viewer = viewerApi({
                        options: {
                            toolbar: false,
                            url: 'src',
                            initialViewIndex: imageIndex
                        },
                        images: this.getImages
                    });
                } else {
                    window.open(file.fileLink, '_blank').focus();
                }
                return false;
            },
            searchJobs(val) {
                // Only trigger API call if search has at least 2 characters
                  if (!val || val.length < 2) return;
      
                  // Set loading state
                  this.isLoading = true;
      
                  // Debounce the API call to avoid too many requests
                  clearTimeout(this.debounce);
                  this.debounce = setTimeout(() => {
                    this.querySearch(val);
                  }, 350);
            },
            showConfirmDelete(file){
                this.$confirm(
                    {
                        message: `Are you sure you delete this file?`,
                        disableKeys: false,
                        auth: false,
                        button: {
                            no: 'No',
                            yes: 'Yes'
                        },
                        callback: confirm => {
                            if (confirm) {
                                this.$api.delete('SystemFiles/' + file.id)
                                    .then(res => {
                                        const index = this.data.findIndex(obj => obj.file.id === file.id);
                                        this.data.splice(index, 1);
                                        this.showSuccessSnack("Deleted!");
                                    }).catch(error => {
                                        this.showErrorSnack("Whoops. We ran into an error.");
                                    });
                            } 
                        }
                    }
                );
            },
            assignJob(rec) {
                if (rec.job && rec.job.id) {
                    this.$confirm(
                        {
                            message: `Are you sure you want to match this file to job ${rec.job.name}?`,
                            disableKeys: false,
                            auth: false,
                            button: {
                                no: 'No',
                                yes: 'Yes'
                            },
                            callback: confirm => {
                                if (confirm) {
                                    this.$api.post('/SystemFiles/linkfile?id='+rec.file.id+"&jobId="+rec.job.id)
                                        .then(res => {
                                            this.showSuccessSnack("File linked!");
                                            rec.file.jobId = rec.job.id;
                                        });
                                } else {
                                    rec.job = null;
                                }
                            }
                        }
                    );
                }
            }
        },
        computed: {
            filteredList() {
                if (this.filter == 'Unmatched'){
                    return this.data.filter(item => {
                        return item.job == null && item.file.jobId == null;
                    });
                } 
                return this.data;
            },
            getImages() {
                if (this.filteredList){
                    return this.filteredList.map(function (item) {
                        return { src: item.file.fileLink, id: item.file.id };
                    });
                }
                return [];
            },
        }
    };
</script>