<template>
    <v-chip v-if="hasSet" :variant="chipVariant" :color="typeColour" :size="chipSize">
        <v-icon v-if="typeIcon" start :icon="typeIcon"></v-icon>
        {{ typeDescription }}
    </v-chip>
</template>
<style scoped>

</style>
<script>
    import { getColourFromInt } from '../mixins/colourMixins'
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "TypeChip",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            type: Number,
            entity: String,
            object: Object,
            size: String,
            variant: String
        },
        methods: {
        },
        computed: {
            chipSize() {
                return !this.size ? 'x-small' : this.size; 
            },
            chipVariant() {
                return !this.variant ? 'flat' : this.variant;
            },
            typeSet() {
                switch (this.entity) {
                    case "WarehouseTransaction":
                        if (this.type === 'undefined' || this.type == null)
                            return null;
                        return this.$constants.warehouse.types[this.type];
                    case "Product":
                         if (this.type == 0  || this.type == null)
                            return null;
                        return this.$constants.products.types[this.type-2];
                    case "Job":
                        if (this.type === 'undefined' || this.type == null)
                            return null;
                        return this.$constants.jobs.statuses[this.type - 1];
                    case "JobItemTask":
                        if (this.type === 'undefined' || this.type == null)
                            return null;
                        return this.$constants.tasks.types[this.type];
                    case "MessageTemplate":
                        return this.$constants.mailTemplates.types[this.type];
                    case "ApplicationUser":
                         if (this.type === 'undefined' || this.type == null)
                            return null;
                        return this.$constants.auth.accessLevels.find(item => item.level == this.type);
                }
                return null;
            },
            hasSet() {
                return this.typeSet !== null && typeof this.typeSet !== "undefined";
            },
            typeColour() {
                if (this.hasSet && this.typeSet.colour !== null && typeof this.typeSet.colour !== 'undefined'){
                    return this.typeSet.colour;
                } else {
                   return getColourFromInt(this.type);
                }
                
            },
            typeIcon() {
                if (!this.hasSet)
                    return null;
                return this.typeSet.icon;
            },
            typeDescription() {
                if (!this.hasSet)
                    return null;
                let desc = this.typeSet.title;
                switch (this.entity) {
                    case "Job":
                        if (this.object && desc && desc.startsWith('Line') && this.object.activeTask && this.object.activeTask.targetStartDate) {
                            desc += " ~ " + this.$dayjs(this.object.activeTask.targetStartDate).format('DD-MM-YYYY');
                        }
                        break;
                }
                return desc;
            }
        }
    };
</script>