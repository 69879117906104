<template>
    <v-table v-if="items" class="small-table text-caption" density="compact">
        <thead>
            <tr>
                <th class="text-left">
                    Product
                </th>
                <th class="text-right">
                    Qty
                </th>
                <th class="text-right" v-if="showValue">
                    Total
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="line in items" :key="line.id">
                <td class="text-left"><job-item-description :line="line"></job-item-description></td>
                <td class="text-right">{{ numberWithCommas(line.quantity, 2) }}</td>
                <td class="text-right" v-if="showValue">{{ numberWithCommas(lineTotal(line), 2) }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr class="total-subline">
                <td class="text-right"><strong>SUBTOTAL</strong></td>
                <td class="text-right"><strong>{{ numberWithCommas(quantityTotal, 2) }}</strong></td>
                <td class="text-right" v-if="showValue"><strong>${{ numberWithCommas(chargeSubTotals, 2) }}</strong></td>
            </tr>
            <tr class="total-subline" v-if="showValue">
                <td colspan="2" class="text-right"><strong>TAX</strong></td>
                <td class="text-right">
                    <strong>${{ numberWithCommas(chargeTaxTotals, 2) }}</strong>
                </td>
            </tr>
            <tr class="total-line" v-if="showValue">
                <td colspan="2" class="text-right"><strong>TOTAL</strong></td>
                <td class="text-right">
                    <strong>${{ numberWithCommas(chargeTotals, 2) }}</strong>
                </td>
            </tr>
        </tfoot>
    </v-table>
</template>
<style scoped>
</style>
<script>
    import { numberWithCommas, roundAway2dp } from "../../mixins/numberMixins";
    function initialState() {
        return {
            loading: false
        };
    }
    export default {
        name: "JobItemsSmall",
        data: function () {
            return initialState();
        },
        mounted: function () {
        },
        props: {
            items: Array,
            hideValue: Boolean
        },
        methods: {
            numberWithCommas,
            lineTotal(line) {
                return (line.totalUnitAmount ?? 0) * line.quantity;
            },
            lineDiscount(line){
                let result = 0;
                line.jobItemCharges.forEach(charge => {
                    if (charge.discount){
                        result = charge.discount * 100;
                    }
                });
                if (result != 0) {
                    return result;
                }
                return null;
            }
        },
        computed: {
            showValue() {
                return typeof this.hideValue == 'undefined' || !this.hideValue;
            },
            chargeTaxTotals() {
                return roundAway2dp(this.chargeSubTotals * 0.15);
            },
            chargeTotals() {
                return this.chargeSubTotals + this.chargeTaxTotals;
            },
            chargeSubTotals() {
                let result = 0;
                this.items.forEach(line => {
                    if (line.totalUnitAmount && line.quantity) {
                        result += line.totalUnitAmount * line.quantity;
                    }
                });
                return result;
            },
            quantityTotal() {
                let result = 0;
                this.items.forEach(line => {
                    if (line.quantity) {
                        result += line.quantity;
                    }
                });
                return result;
            },
        }
    };
</script>